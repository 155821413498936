import Modal from 'react-modal'

const ErrorModal = ({ visible, message, onClose, onCloseClick }) => {
  Modal.setAppElement('#__next');
  
  return (
    <Modal
      isOpen={visible}
      className="centered relative w-auto items-center sm:max-w-md sm:mx-auto m-2 my-6 outline-none"
      overlayClassName="fixed inset-0 bg-cool-gray-500 bg-opacity-75 overflow-x-hidden overflow-y-auto"
      onRequestClose={() => onClose()}
      shouldCloseOnOverlayClick={false}
      shouldCloseOnEsc={false}
      closeTimeoutMS={500}
    >
      <div className="w-full mx-auto bg-white bg-clip-padding rounded-lg shadow-xl p-6 sm:p-10 outline-none">
        <div className="flex flex-col">
          <p className="text-2xl font-semibold text-danger mb-3">Oops!</p>
          <span className="text-base">{message || ""}</span>
        </div>
        <div className="mt-6 sm:mt-10">
          <span className="flex w-full rounded-md shadow-sm ">
            <button onClick={(e) => onCloseClick(e)} type="button" className="inline-flex items-center justify-center w-full rounded-md border border-transparent px-6 py-3 bg-brand-500 text-base leading-6 font-medium text-white shadow-sm hover:bg-brand-700 focus:outline-none focus:border-brand-700 focus:shadow-outline-red transition ease-in-out duration-150">
              Close
            </button>
          </span>
        </div>
      </div>
    </Modal>
  );
}

export default ErrorModal;