import React, { useState, useEffect } from 'react'
import Modal from 'react-modal'
import cn from 'classnames'

import StringUtils from '../utils/StringUtils'

const BpiCcStep1Modal = ({ visible, onClose, biller, accounts, account, mode, loading, error,onActionClick }) => {
  Modal.setAppElement('#__next');
  const [selectedAccount, setSelectedAccount] = useState(undefined);
  const [selectedMode, setSelectedMode] = useState(undefined);
  const [isLoading, setIsLoading] = useState(false);
  const modes = [
    { text: "Minimum amount due", value: 'Minimum amount due' },
    { text: "Total amount due", value: 'Total amount due' },
  ];

  /* useEffect(() => {
    console.log('accounts', accounts);
  }, [accounts]) */

  useEffect(() => {
    if (account) {
      //setSelectedAccount(accounts.find(a => a.id == account));
      setSelectedAccount(accounts.find(a => a.id == account.id));
    }
  }, [account]);

  useEffect(() => {
    if (mode) {
      setSelectedMode(mode);
    }
  }, [mode]);

  useEffect(() => {
    setIsLoading(loading);
  }, [loading]);

  const onAccountChecked = (id) => {
    setSelectedAccount(accounts.find(a => a.id == id))
  }

  const onModeChecked = (mode) => {
    setSelectedMode(mode);
  }

  const onNextClick = (e) => {
    e.preventDefault();
    setIsLoading(true);
    onActionClick({ creditAccount: selectedAccount, mode: selectedMode });
  }
 
  return (
    <Modal
      isOpen={visible}
      className="centered relative w-auto items-center sm:max-w-md m-2 sm:mx-auto my-6 outline-none"
      overlayClassName="fixed inset-0 bg-cool-gray-500 bg-opacity-75 overflow-x-hidden overflow-y-auto"
      onRequestClose={() => onClose()}
      shouldCloseOnOverlayClick={false}
      shouldCloseOnEsc={false}
      closeTimeoutMS={500}
    >
      <div className="w-full bg-white bg-clip-padding rounded-lg shadow-xl p-6 sm:p-10 outline-none relative">
        <button className="h-auto w-auto p-2 bg-transparent outline-none focus:outline-none absolute top-1 right-1 text-gray-400 hover:text-gray-600 transition-opacity duration-300 ease-in" onClick={() => onClose()}>
          <svg className="w-6 h-6" fill="currentColor" viewBox="0 0 20 20" xmlns="http://www.w3.org/2000/svg"><path fillRule="evenodd" d="M4.293 4.293a1 1 0 011.414 0L10 8.586l4.293-4.293a1 1 0 111.414 1.414L11.414 10l4.293 4.293a1 1 0 01-1.414 1.414L10 11.414l-4.293 4.293a1 1 0 01-1.414-1.414L8.586 10 4.293 5.707a1 1 0 010-1.414z" clipRule="evenodd"></path></svg>
        </button>

        <div>
          <div className="flex items-center">
            <img className="h-12 w-12 rounded-full border border-gray-200 shadow-sm overflow-hidden" src={biller !== undefined ? biller.image : ''} alt={biller !== undefined ? biller.name : ''} />
            <div className="pl-3 flex-1">
              <p className="leading-5 text-gray-700 font-medium">
                {biller !== undefined ? biller.name : ''}
              </p>
            </div>
          </div>
          <div className="mt-8 mb-6">
            <p className="text-gray-700 mb-4">Select a Credit Card Customer Number to enroll:</p>
            {
              accounts.map((a, index) => (
                <div key={index} className="mb-3 flex items-start">
                  <input id={`card_${index}`} name={`card_${index}`} type="radio" checked={selectedAccount ? a.id === selectedAccount.id : false} onChange={() => onAccountChecked(a.id)} className="form-radio mt-1 h-4 w-4 text-brand-500 rounded-full focus:ring-red-200 focus:ring transition duration-150 ease-in-out" />
                  <label htmlFor={`card_${index}`} className="ml-3">
                    {/* <span className="block text-sm font-medium">{a.name}</span> */}
                    <span className="block text-sm font-medium leading-5">{a.number}</span>
                  </label>
                </div>
              ))
            }
          </div>
          <div className="mt-2">
            <p className="text-gray-700 mb-4">Select an Auto Debit Payment mode:</p>
            {
              modes.map((mode, index) => 
                <div key={index} className="mb-3 flex items-center">
                  <input id={`mode_${index}`} name={`mode_${index}`} type="radio" checked={selectedMode ? mode.value === selectedMode : false} onChange={() => onModeChecked(mode.value)} className="form-radio mb-0.5 h-4 w-4 text-brand-500 rounded-full focus:ring-red-200 focus:ring transition duration-150 ease-in-out" />
                  <label htmlFor={`mode_${index}`} className="ml-3">
                    <span className="block text-sm font-medium">{mode.text}</span>
                  </label>
                </div>
              )
            }
          </div>
        </div>
        
        <div className="mt-10">
          <p className="w-full text-sm text-danger font-medium text-center mb-1">{error}</p>
          <span className="flex w-full rounded-md shadow-sm">
            <button disabled={!selectedAccount || !selectedMode || isLoading} onClick={onNextClick} type="button" className={cn("inline-flex items-center justify-center w-full rounded-md border border-transparent px-6 py-3 bg-brand-500 text-base leading-6 font-medium text-white shadow-sm focus:outline-none focus:border-brand-700 focus:shadow-outline-red transition ease-in-out duration-150", {
              'opacity-50 cursor-default': !selectedAccount || !selectedMode || isLoading, 
              'opacity-100 cursor-pointer hover:bg-brand-700': selectedAccount && selectedMode && !isLoading})}>
              { isLoading && <svg className="animate-spin -ml-1 mr-3 h-4 w-4 text-white" xmlns="http://www.w3.org/2000/svg" fill="none" viewBox="0 0 24 24">
                <circle className="opacity-25" cx="12" cy="12" r="10" stroke="currentColor" strokeWidth="4"></circle>
                <path className="opacity-75" fill="currentColor" d="M4 12a8 8 0 018-8V0C5.373 0 0 5.373 0 12h4zm2 5.291A7.962 7.962 0 014 12H0c0 3.042 1.135 5.824 3 7.938l3-2.647z"></path>
              </svg>
              }
              Next
            </button>
          </span>
        </div>
      </div>
    </Modal>
  );
}

export default BpiCcStep1Modal;