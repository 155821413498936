import React, { useState, useEffect } from 'react'
import Modal from 'react-modal'

const Step5Modal = ({ visible, onClose, biller, referenceNumber, onActionClick }) => {
  Modal.setAppElement('#__next');
  const [refNum, setRefNum] = useState('')

  useEffect(() => {
    setRefNum(referenceNumber)
  }, [referenceNumber])
 
  return (
    <Modal
      isOpen={visible}
      className="centered relative w-auto items-center sm:max-w-md m-4 sm:mx-auto my-6 outline-none"
      overlayClassName="fixed inset-0 bg-cool-gray-500 bg-opacity-75 overflow-x-hidden overflow-y-auto"
      onRequestClose={() => onClose()}
      shouldCloseOnOverlayClick={false}
      shouldCloseOnEsc={false}
      closeTimeoutMS={500}
    >
      <div className="w-full bg-white bg-clip-padding rounded-lg shadow-xl p-6 sm:p-10 outline-none relative">
        <button className="h-auto w-auto p-2 bg-transparent outline-none focus:outline-none absolute top-1 right-1 text-gray-400 hover:text-gray-600 transition-opacity duration-300 ease-in" onClick={() => onClose()}>
          <svg className="w-6 h-6" fill="currentColor" viewBox="0 0 20 20" xmlns="http://www.w3.org/2000/svg"><path fillRule="evenodd" d="M4.293 4.293a1 1 0 011.414 0L10 8.586l4.293-4.293a1 1 0 111.414 1.414L11.414 10l4.293 4.293a1 1 0 01-1.414 1.414L10 11.414l-4.293 4.293a1 1 0 01-1.414-1.414L8.586 10 4.293 5.707a1 1 0 010-1.414z" clipRule="evenodd"></path></svg>
        </button>
        
        <div className="text-center">
          <img className="h-16 w-16 sm:h-24 sm:w-24 rounded-full border border-gray-200 shadow-sm overflow-hidden mx-auto" src={biller !== undefined ? biller.image : ''} alt={biller !== undefined ? biller.name : ''} />
          <div className="mt-6">
            <p className="leading-5 text-xl font-semibold">Autodebit Enrollment</p>
          </div>
          <div className="mt-8 text-left">
            <p className="text-gray-800 text-sm mb-3">You have enrolled for auto-debit arrangement with the following details:</p>
            <p className="text-gray-800 text-sm">
              Biller:&nbsp;<span className="font-medium text-black">{biller !== undefined ? biller.name : ''}</span>
              <br/>
              Reference number:&nbsp;<span className="font-medium text-black">{refNum}</span>
              <br />
            </p>
            <p className="mt-4 mb-1 text-left text-xs text-gray-400 font-medium uppercase">
              What comes next:
            </p>
            <ul className="list-disc text-sm text-gray-800 pl-4">
              {biller && biller.metadata.post_reminder_override && biller.metadata.post_reminder_override.length > 0 
                ? biller.metadata.post_reminder_override.map((message, i) => <li key={i}>{message}</li>)  
                :
                <>
                  <li>Your account will be automatically charged on the payment schedule indicated in your bill.</li>
                  <li>Your account needs to be funded when the charge will be made.</li>
                  <li>Please make sure the charge will be successful by funding your account. You may incur penalties from your biller if payment fails.</li>
                  <li>Duplicate enrollment with the same account number and same reference number under {biller !== undefined ? biller.name : ''} will only be enrolled once. No double debit shall be done on your account.</li>
                </>
              }
            </ul>
          </div>
        </div>
        
        <div className="mt-10 sm:mt-12">
          <span className="flex w-full rounded-md shadow-sm">
            <button onClick={() => onActionClick()} type="button" className="inline-flex justify-center w-full rounded-md border border-transparent px-6 py-3 bg-brand-500 text-base leading-6 font-medium text-white shadow-sm hover:bg-brand-700 focus:outline-none focus:border-brand-700 focus:shadow-outline-red transition ease-in-out duration-150">
              Enroll another biller
            </button>
          </span>
        </div>
      </div>
    </Modal>
  );
}

export default Step5Modal;