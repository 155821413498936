import React, { useState, useEffect } from 'react'
import Modal from 'react-modal'
import cn from 'classnames'

import StringUtils from '../utils/StringUtils'

const BpiCcStep2Modal = ({ visible, onClose, biller, creditAccount, accounts, account, loading, error, onActionClick }) => {
  Modal.setAppElement('#__next');
  const [sources, setSources] = useState(accounts);
  const [selectedAccount, setSelectedAccount] = useState(undefined);
  const [isLoading, setIsLoading] = useState(false);

  useEffect(() => {
    if (accounts) {
      //console.log('accounts', accounts);
      setSources(accounts);
    }
  }, [accounts])

  useEffect(() => {
    if (selectedAccount) {
      setSelectedAccount(accounts.find(a => a.id == account));
    }
  }, [account]);

  useEffect(() => {
    setIsLoading(loading);
  }, [loading]);

  const onAccountChecked = (id) => {
    setSelectedAccount(accounts.find(a => a.id == id))
  }

  const onNextClick = (e) => {
    e.preventDefault();
    setIsLoading(true);
    onActionClick({ account: selectedAccount });
  }
 
  return (
    <Modal
      isOpen={visible}
      className="centered relative w-auto items-center sm:max-w-md m-2 sm:mx-auto my-6 outline-none"
      overlayClassName="fixed inset-0 bg-cool-gray-500 bg-opacity-75 overflow-x-hidden overflow-y-auto"
      onRequestClose={() => onClose()}
      shouldCloseOnOverlayClick={false}
      shouldCloseOnEsc={false}
      closeTimeoutMS={500}
    >
      <div className="w-full bg-white bg-clip-padding rounded-lg shadow-xl p-6 sm:p-10 outline-none relative">
        <button className="h-auto w-auto p-2 bg-transparent outline-none focus:outline-none absolute top-1 right-1 text-gray-400 hover:text-gray-600 transition-opacity duration-300 ease-in" onClick={() => onClose()}>
          <svg className="w-6 h-6" fill="currentColor" viewBox="0 0 20 20" xmlns="http://www.w3.org/2000/svg"><path fillRule="evenodd" d="M4.293 4.293a1 1 0 011.414 0L10 8.586l4.293-4.293a1 1 0 111.414 1.414L11.414 10l4.293 4.293a1 1 0 01-1.414 1.414L10 11.414l-4.293 4.293a1 1 0 01-1.414-1.414L8.586 10 4.293 5.707a1 1 0 010-1.414z" clipRule="evenodd"></path></svg>
        </button>

        <div>
          <div className="flex items-center">
            <img className="h-12 w-12 rounded-full border border-gray-200 shadow-sm overflow-hidden" src={biller !== undefined ? biller.image : ''} alt={biller !== undefined ? biller.name : ''} />
            <div className="pl-3 flex-1">
              <p className="leading-5 text-gray-700 font-medium">
                {biller !== undefined ? biller.name : ''}
              </p>
              <p className="leading-4 text-gray-500 text-xs">
                <span className="inline font-sans">Customer Number: </span>
                <span className="inline font-mono">{creditAccount ? creditAccount.number : ''}</span>
              </p>
            </div>
          </div>
          <div className="mt-10 mb-6">
            <p className="text-gray-700 mb-4">Select your BPI account for this enrollment:</p>
            {
              sources.map((a) => 
                <div key={a.id} className="mb-3 flex items-start">
                  <input id={`txt_${a.id}`} name="form-input push_notifications" type="radio" checked={selectedAccount ? a.id === selectedAccount.id : false} onChange={() => onAccountChecked(a.id)} className="form-radio mt-1 h-4 w-4 text-brand-500 rounded-full focus:ring-red-200 focus:ring transition duration-150 ease-in-out" />
                  <label htmlFor={`txt_${a.id}`} className="ml-3">
                    <span className="block text-sm font-medium">{a.bank_account.account_name}</span>
                    <span className="block text-xs leading-5 text-gray-600">{`${StringUtils.titleCase(a.bank_account.account_type)} ${a.bank_account.account_number}`}</span>
                  </label>
                </div>
              )
            }
          </div>
          <div className="mt-10 py-4 border-t border-gray-200">
            <p className="text-gray-500 text-13 leading-5 mb-4 text-justify">The account you select will be debited for this enrollment. You will need to make sure that the account would be properly funded when charging happens.</p>
          </div>
        </div>
        
        <div className="mt-2">
          <p className="w-full text-sm text-danger font-medium text-center mb-1">{error}</p>
          <span className="flex w-full rounded-md shadow-sm">
            <button disabled={selectedAccount === undefined || isLoading} onClick={(e) => onNextClick(e)} type="button" className={cn("inline-flex items-center justify-center w-full rounded-md border border-transparent px-6 py-3 bg-brand-500 text-base leading-6 font-medium text-white shadow-sm focus:outline-none focus:border-brand-700 focus:shadow-outline-red transition ease-in-out duration-150", { 'opacity-50': selectedAccount === undefined || isLoading, 'cursor-not-allowed': selectedAccount === undefined || isLoading, 'hover:bg-brand-700': selectedAccount !== undefined || !isLoading})}>
              { isLoading && <svg className="animate-spin -ml-1 mr-3 h-4 w-4 text-white" xmlns="http://www.w3.org/2000/svg" fill="none" viewBox="0 0 24 24">
                <circle className="opacity-25" cx="12" cy="12" r="10" stroke="currentColor" strokeWidth="4"></circle>
                <path className="opacity-75" fill="currentColor" d="M4 12a8 8 0 018-8V0C5.373 0 0 5.373 0 12h4zm2 5.291A7.962 7.962 0 014 12H0c0 3.042 1.135 5.824 3 7.938l3-2.647z"></path>
              </svg>
              }
              Next
            </button>
          </span>
        </div>
      </div>
    </Modal>
  );
}

export default BpiCcStep2Modal;