import Modal from 'react-modal'

const LoadingModal = ({ visible }) => {
  Modal.setAppElement('#__next');
  
  return (
    <Modal
      isOpen={visible}
      className="centered relative w-auto items-center sm:max-w-md sm:mx-auto m-2 my-6 outline-none"
      overlayClassName="fixed inset-0 bg-cool-gray-500 bg-opacity-75 overflow-x-hidden overflow-y-auto"
      shouldCloseOnOverlayClick={false}
      shouldCloseOnEsc={false}
      closeTimeoutMS={500}
    >
      <div className="w-auto mx-auto bg-white bg-clip-padding rounded-lg shadow-xl p-6 sm:p-10 outline-none">
        <div className="flex items-center justify-center">
          <svg className="animate-spin -ml-1 mr-3 h-6 w-6 text-brand-500" xmlns="http://www.w3.org/2000/svg" fill="none" viewBox="0 0 24 24">
            <circle className="opacity-25" cx="12" cy="12" r="10" stroke="currentColor" strokeWidth="4"></circle>
            <path className="opacity-75" fill="currentColor" d="M4 12a8 8 0 018-8V0C5.373 0 0 5.373 0 12h4zm2 5.291A7.962 7.962 0 014 12H0c0 3.042 1.135 5.824 3 7.938l3-2.647z"></path>
          </svg>
          <span className="ml-2 text-xl">Fetching your accounts...</span>
        </div>
      </div>
    </Modal>
  );
}

export default LoadingModal;