const getDigitsSum = (arr) => {
  //console.log('digit array', arr);
  return parseInt(arr[0]) + parseInt(arr[1]);
}

const getBplacCheckDigit = (value) => {
  let a = value.split('');
  //console.log('nine digits', value);
  //console.log('split', a);
  //console.log('length', a.length);
  let products = [];
  a.forEach((b, index) => {
    const c = parseInt(b) * (a.length - index);
    products.push(c);
  })
  //console.log('products', products);
  let sums = [];
  products.forEach((x) => {
    if (x > 9) {
      let y = getDigitsSum(x.toString().split(''));
      while (y > 9) {
        y = getDigitsSum(y.toString().split(''));
      }
      sums.push(y);
    } else {
      sums.push(x);
    }
  })
  //console.log('sums', sums);
  const sum = sums.reduce((i, j) => i + j, 0);
  //console.log('sum', sum);
  const modulo = sum % 10;
  //console.log('modulo', modulo);
  if (modulo !== 0) {
    return (10 - modulo)
  } else {
    return modulo;
  }
}

const isBplacValid = (input) => {
  const pattern_1_a = /^(\d{1})(\d{1})(\d{7})(\d{1})([TL ]?)$/;
  const pattern_1_b = /^(\d{1})(\d{2})(\d{6})(\d{1})([TL ]?)$/;
  const pattern_2 = /^(\d{1})(\d{1})(\d{7})(\d{1})([TL ]?)(\d{3})$/;

  let string = input;
  // Add space if string is exactly 10 chars 
  if (string.length === 10) string = string + ' ';
  const valid_lengths = [10, 11, 13, 14];

  if (!valid_lengths.includes(string.length)) {
    // ERR: Invalid length
    //console.log('err', 'invalid length');
    return false;
  }

  // First test: begins with 10 digits

  if (!string.match(/^\d{10}/)) {
    // ERR: First 10 chars must be numeric
    //console.log('err', 'first 10 chars must be numeric')
    return false;
  }

  // Check first digit
  const valid_first_digits = [0, 4, 7, 8, 9];
  const first_digit = string.match(/^(\d{1})/)[1];
  if (!valid_first_digits.includes(parseInt(first_digit))) {
    // ERR: Invalid first digit
    //console.log('err', 'invalid first digit');
    return false;
  }

  if (string.match(pattern_1_a) || string.match(pattern_1_b)) {
    let second_digits = '';
    let allowed_second_digits = [];
    if (first_digit === '0') {
        second_digits = string.match(pattern_1_b)[2];
        allowed_second_digits = ['07', '09'];
        // ERR: Invalid second and third digit
        if (!allowed_second_digits.includes(second_digits)) {
          //console.log('err', 'invalid second digit');
          return false;
        }
    } else {
        second_digits = string.match(pattern_1_a)[2];
        if (first_digit === '9') {
            allowed_second_digits = [ '7', '8', '9'];
            // ERR: Invalid second digit
            if (!allowed_second_digits.includes(second_digits)) {
              //console.log('err', 'invalid second digit');
              return false;
            }
        }
        if (first_digit === '4') {
            allowed_second_digits = [ '2' ];
            // ERR: Invalid second digit
            if (!allowed_second_digits.includes(second_digits)) {
              //console.log('err', 'invalid second digit');
              return false;
            }
        }
    }
  } else if (string.match(pattern_2)) {
    let second_digits = '';
    let last_three_digits = '';
    let allowed_second_digits = [];
    if (first_digit === '0') {
      second_digits = string.match(pattern_1_b)[2];
      allowed_second_digits = ['07', '09'];
      // ERR: Invalid second and third digit
      if (!allowed_second_digits.includes(second_digits)) {
        //console.log('err', 'invalid second digit');
        return false;
      }
    } else {
        second_digits = string.match(pattern_2)[2];
        if (first_digit === '9') {
            last_three_digits = string.match(pattern_2)[6];
            allowed_second_digits = [ '9' ];
            // ERR: Invalid second digit
            if (!allowed_second_digits.includes(second_digits)) {
              //console.log('err', 'invalid second digit, should be 9');
              return false;
            }

            if (parseInt(last_three_digits) < 100) {
              // ERR: last 3 digits must be 100-999
              //console.log('err', 'invalid extension digits, should be 100-999');
              return false;
            }
        }
        if (first_digit === '4') {
            allowed_second_digits = [ '2' ];
            // ERR: Invalid second digit
            if (!allowed_second_digits.includes(second_digits)) {
              //console.log('err', 'invalid second digit, should be 2');
              return false;
            }
        }
    }
  }



  const nine_digits_and_check_digit = string.match(/^(\d{9})(\d{1})/);
  const second_to_ninth_digits = string.match(/^(\d{1})(\d{8})/);
  const checkDigit = getBplacCheckDigit(nine_digits_and_check_digit[1]);
  //console.log('check_digit', checkDigit);
  if (second_to_ninth_digits[2] === '00000000') {
    // ERR: All zeroes not allowed
    //console.log('err', 'all zeroes not allowed on 2nd to 9th digits')
    return false;
  } else {
    if (checkDigit !== parseInt(nine_digits_and_check_digit[2])) {
      // console.log('Not valid check digit; should be ' + check_digit(nine_digits_and_check_digit[1]));
      // ERR: Invalid check digit
      //console.log('err', `invalid check digit; should be ${checkDigit}`);
      return false
    } else {
      return true;
    }
  }
}

const getPalCheckDigit = (value) => {
  let a = value.split('');
  //console.log('nine digits', value);
  //console.log('split', a);
  //console.log('length', a.length);
  let products = [];
  a.forEach((b, index) => {
    const c = parseInt(b) * (a.length - index);
    products.push(c);
  })
  //console.log('products', products);
  let sums = [];
  products.forEach((x) => {
    if (x > 9) {
      let y = getDigitsSum(x.toString().split(''));
      while (y > 9) {
        y = getDigitsSum(y.toString().split(''));
      }
      sums.push(y);
    } else {
      sums.push(x);
    }
  })
  //console.log('sums', sums);
  const sum = sums.reduce((i, j) => i + j, 0);
  //console.log('sum', sum);
  const modulo = sum % 10;
  //console.log('modulo', modulo);
  if (modulo !== 0) {
    return (10 - modulo)
  } else {
    return modulo;
  }
}

const isPalValid = (input) => {
  const pattern_1 = /^(\d{1})(\d{1})(\d{7})(\d{1})([TL ]?)$/;
  const pattern_2 = /^(\d{1})(\d{1})(\d{7})(\d{1})([TL ]?)(\d{3})$/;

  let string = input;
  // Add space if string is exactly 10 chars 
  if (string.length === 10) string = string + ' ';
  const valid_lengths = [10, 11, 13, 14];

  if (!valid_lengths.includes(string.length)) {
    // ERR: Invalid length
    console.log('err', 'invalid length');
    return false;
  }

  // Check first digit
  const valid_first_digits = [0, 1, 2, 4, 6, 9];
  const first_digit = string.match(/^(\d{1})/)[1];
  if (!valid_first_digits.includes(parseInt(first_digit))) {
    // ERR: Invalid first digit
    //console.log('err', 'invalid first digit');
    return false;
  }

  if (string.match(pattern_1)) {
    let second_digits = '';
    let allowed_second_digits = [];
    if (first_digit === '4') {
        second_digits = string.match(pattern_1)[2];
        console.log('second digit', second_digits);
        allowed_second_digits = ['1'];
        // ERR: Invalid second and third digit
        if (!allowed_second_digits.includes(second_digits)) {
          //console.log('err', 'invalid second digit, should be 1');
          return false;
        }
    }
  } else if (string.match(pattern_2)) {
    let second_digits = '';
    let allowed_second_digits = [];
    let last_three_digits = '';
    if (first_digit === '4') {
      second_digits = string.match(pattern_1)[2];
      //console.log('second digit', second_digits);
      allowed_second_digits = ['1'];
      // ERR: Invalid second and third digit
      if (!allowed_second_digits.includes(second_digits)) {
        //console.log('err', 'invalid second digit, should be 1');
        return false;
      }
    } else if (first_digit === '9' || first_digit === '0') {
      second_digits = string.match(pattern_2)[2];
      last_three_digits = string.match(pattern_2)[6];
      //console.log('second digit', second_digits);
      allowed_second_digits = ['0', '1', '5' ];
      // ERR: Invalid second and third digit
      if (!allowed_second_digits.includes(second_digits)) {
        //console.log('err', 'invalid second digit, should be 0, 1, or 5');
        return false;
      }
      
      if (parseInt(last_three_digits) < 100) {
        // ERR: last 3 digits must be 100-999
        //console.log('err', 'invalid extension digits, should be 100-999');
        return false;
      }
    }
  } else {
    // Err: Invalid pattern
    console.log('err', 'invalid pattern');
    return false;
  }
  

  const nine_digits_and_check_digit = string.match(/^(\d{9})(\d{1})/);
  const checkDigit = getPalCheckDigit(nine_digits_and_check_digit[1]);
  if (nine_digits_and_check_digit[1] === '000000000' && nine_digits_and_check_digit[2] === '0') {
    // ERR: All zeroes not allowed
    console.log('err', 'all zeroes not allowed')
    return false;
  } else {
    if (checkDigit !== parseInt(nine_digits_and_check_digit[2])) {
      // console.log('Not valid check digit; should be ' + check_digit(nine_digits_and_check_digit[1]));
      // ERR: Invalid check digit
      //console.log('err', `invalid check digit; should be ${checkDigit}`);
      return false
    } else {
        return true;
    }
  }
}

const getMamtcCheckDigit = (value) => {
  let a = value.split('');
  //console.log('nine digits', value);
  //console.log('split', a);
  //console.log('length', a.length);
  const factors = [9, 7, 5, 3, 9, 7, 5, 3, 9];
  let products = [];
  a.forEach((b, index) => {
    const c = parseInt(b) * factors[index];
    products.push(c);
  })
  //console.log('products', products);
  
  //console.log('sums', sums);
  const sum = products.reduce((i, j) => i + j, 0);
  //console.log('sum', sum);
  const modulo = sum % 10;
  //console.log('modulo', modulo);
  if (modulo !== 0) {
    return (10 - modulo)
  } else {
    return modulo;
  }
}

const isMamtcValid = (input) => {
  const pattern = /^(\d{2})(\d{7})(\d{1})$/;

  let string = input;

  if (string.length !== 10) {
    // ERR: Invalid length
    console.log('err', 'invalid length');
    return false;
  }


  if (string.match(pattern)) {
    const firstTwoDigits = string.match(pattern)[1];
    //console.log('firstTwoDigits', firstTwoDigits);
    if (parseInt(firstTwoDigits) < 17) {
      //console.log('err', 'invalid first two digits, should be not less than 17');
      return false;
    }
  } else {
    // Err: Invalid pattern
    console.log('err', 'invalid pattern');
    return false;
  }
  

  const nine_digits_and_check_digit = string.match(/^(\d{9})(\d{1})/);
  const checkDigit = getMamtcCheckDigit(nine_digits_and_check_digit[1]);
  if (checkDigit !== parseInt(nine_digits_and_check_digit[2])) {
    // console.log('Not valid check digit; should be ' + check_digit(nine_digits_and_check_digit[1]));
    // ERR: Invalid check digit
    //console.log('err', `invalid check digit; should be ${checkDigit}`);
    return false
  } else {
    return true;
  }
}

const getTfsCheckDigit = (value) => {
  let a = value.split('');
  //console.log('nine digits', value);
  //console.log('split', a);
  //console.log('length', a.length);
  const factors = [2, 9, 8, 7, 6, 5, 4, 3, 2];
  let step1 = [];
  a.forEach((b, index) => {
    const c = parseInt(b) * factors[index];
    step1.push(c);
  })
  //console.log('step1: products of 9digits * weight factor =', step1);
  
  const step2 = step1.reduce((i, j) => i + j, 0);
  //console.log('step2: sum of products =', step2);

  //step 3
  const step3 = Math.floor(step2 / 11);
  //const modulo = sum % 11;
  //console.log('step3: quotient of step2 / 11 =', step3);

  const step4 = step3 * 11;
  //console.log('step4: product of step3 * 11 =', step4)

  const step5 = step2 - step4;
  //console.log('step5: difference of step2 - step4 =', step5)

  const step6 = 11 - step5;
  //console.log('step6: difference of 11 - step5 =', step6)

  const step7 = step6 % 10;

  const checkDigit = step6 > 9 ? step7 : step6
  //console.log('check digit is ', checkDigit)

  return checkDigit;
}

const isTfsValid = (input) => {
  const pattern = /^(\d{9})(\d{1})$/;

  let string = input;

  if (string.length !== 10) {
    // ERR: Invalid length
    console.log('err', 'invalid length');
    return false;
  }


  if (string.match(pattern)) {
    const nine_digits_and_check_digit = string.match(pattern);
    const checkDigit = getTfsCheckDigit(nine_digits_and_check_digit[1]);
    const firstNineDigits = parseInt(nine_digits_and_check_digit[1]);
    if (firstNineDigits > 0 && firstNineDigits < 1000000000) {
      if (checkDigit !== parseInt(nine_digits_and_check_digit[2])) {
        // console.log('Not valid check digit; should be ' + check_digit(nine_digits_and_check_digit[1]));
        // ERR: Invalid check digit
        //console.log('err', `invalid check digit; should be ${checkDigit}`);
        return false
      } else {
        return true;
      }
    } else {
      // Err: Invalid pattern
      console.log('err', 'invalid first nine digits, should be in range 000000001 - 999999999');
      return false;
    }
  } else {
    // Err: Invalid pattern
    console.log('err', 'invalid pattern');
    return false;
  }
}

const getPioneerLifeCheckDigit = (value) => {
  let a = value.split('');
  //console.log('fifteen digits', value);
  //console.log('split', a);
  //console.log('length', a.length);
  const factors = [16, 15, 14, 13, 12, 11, 10, 9, 8, 7, 6, 5, 4, 3, 2];
  let step1 = [];
  a.forEach((b, index) => {
    const c = parseInt(b) * factors[index];
    step1.push(c);
  })
  //console.log('step1: products of 9digits * weight factor =', step1);
  
  const step2 = step1.reduce((i, j) => i + j, 0);
  //console.log('step2: sum of products =', step2);

  //step 3
  const step3 = step2 % 11;
  //const modulo = sum % 11;
  //console.log('step3: quotient of step2 / 11 =', step3);

  const checkDigit = step3 > 1 ? (11 - step3) : step3;
  //console.log('check digit is ', checkDigit)

  return checkDigit;
}

const isPioneerLifeValid = (input) => {
  const pattern = /^(\d{15})(\d{1})$/;

  let string = input;

  if (string.length !== 16) {
    // ERR: Invalid length
    console.log('err', 'invalid length');
    return false;
  }


  if (string.match(pattern)) {
    const fifteen_digits_and_check_digit = string.match(pattern);
    const checkDigit = getPioneerLifeCheckDigit(fifteen_digits_and_check_digit[1]);
    //const firstFifteenDigits = parseInt(fifteen_digits_and_check_digit[1]);
    if (checkDigit !== parseInt(fifteen_digits_and_check_digit[2])) {
      // console.log('Not valid check digit; should be ' + check_digit(nine_digits_and_check_digit[1]));
      // ERR: Invalid check digit
      //console.log('err', `invalid check digit; should be ${checkDigit}`);
      return false
    } else {
      return true;
    }
    
  } else {
    // Err: Invalid pattern
    console.log('err', 'invalid pattern');
    return false;
  }
}

const getNorkisCheckDigit = (value) => {
  let a = value.split('').reverse();
  //console.log('nine digits', value);
  //console.log('split', a);
  //console.log('length', a.length);
  const factors = [2, 3, 4, 5, 6, 7, 8, 9, 10];
  let step1 = [];
  a.forEach((b, index) => {
    const c = parseInt(b) * factors[index];
    step1.push(c);
  })
  //console.log('step1: products of 9digits * weight factor =', step1);
  
  const step2 = step1.reduce((i, j) => i + j, 0);
  //console.log('step2: sum of products =', step2);

  //step 3
  const step3 = step2 % 11;
  //const modulo = sum % 11;
  //console.log('step3: remainder of step2 % 11 =', step3);

  if (step3 === 1) {
    return -1;
  }

  const checkDigit = step3 > 0 ? (11 - step3) : step3;
  //console.log('check digit is ', checkDigit)

  return checkDigit;
}

const isNorkisValid = (input) => {
  // NNN 999999999 C AAAAAAAAAAAAAAAAA
  const pattern = /^(\d{3})(\d{9})(\d{1})([a-zA-Z -.]{2,17})$/;

  let string = input;

  // Check first three digits
  const valid_first_three_digits = [310, 200, 210, 220, 230, 240, 250, 260, 270, 190];
  const first_three_digits = string.match(pattern)[1];
  //console.log('first_three_digits', first_three_digits);
  if (!valid_first_three_digits.includes(parseInt(first_three_digits))) {
    // ERR: Invalid first digit
    //console.log('err', 'invalid first three digits');
    return false;
  }

  if (string.match(pattern)) {
    const second_group = string.match(pattern)[2];
    //console.log('second_group', second_group);
    if (parseInt(second_group) > 0 && parseInt(second_group) < 1000000000) {
      const ref_check_digit = string.match(pattern)[3];
      const check_digit = getNorkisCheckDigit(second_group);
      //console.log('ref_check_digit', ref_check_digit);
      if (check_digit === -1) {
        //console.log('err', `invalid reference number; check digit value should not be ${check_digit}`);
        return false;
      } else {
        if (check_digit !== parseInt(ref_check_digit)) {
          // ERR: Invalid check digit
          //console.log('err', `invalid check digit; should be ${check_digit}`);
          return false
        } else {
          return true;
        }
      }
    } else {
      // Err: Invalid pattern
      //console.log('err', 'invalid second group, should be in range 000000001 - 999999999');
      return false;
    }
  } else {
    // Err: Invalid pattern
    //console.log('err', 'invalid pattern');
    return false;
  }
}

const getSmdcCheckDigit = (value) => {
  const weight = 3;
  let factor = 10;
  let result = 0;

  const a = value.split('');
  //console.log('ten digits split', a);
  
  a.forEach((b, index) => {
    let mod10 = (parseInt(b) + factor) % 10;
    if (mod10 === 0) {
      mod10 = 10;
    }
    //console.log(`mod10[${index}]: `, mod10);
    result = (mod10 * weight) % 11;
    if (index < a.length) {
      factor = result;
      //console.log(`next factor: `, factor)
    }
  })
  //console.log('result: ', result);

  const checkDigit = (11 - result) % 10;
  //console.log('check digit is ', checkDigit)

  return checkDigit;
}

const isSmdcValid = (input) => {
  // NNN 999999999 C AAAAAAAAAAAAAAAAA
  const pattern = /^(\d{10})([-]?)(\d{1})$/;

  let string = input;

  if (string.match(pattern)) {
    const ten_digits = string.match(pattern)[1];
    //console.log('ten_digits', ten_digits);

    const ref_check_digit = string.match(pattern)[3];
    //console.log('ref_check_digit', ref_check_digit);

    const check_digit = getSmdcCheckDigit(ten_digits);

    if (check_digit !== parseInt(ref_check_digit)) {
      // ERR: Invalid check digit
      //console.log('err', `invalid check digit; should be ${check_digit}`);
      return false
    } else {
      return true;
    }
  } else {
    // Err: Invalid pattern
    //console.log('err', 'invalid pattern');
    return false;
  }
}

const getFlanCheckDigit = (value) => {
  let a = value.split('');
  //console.log('ten digits', value);
  //console.log('split', a);
  //console.log('length', a.length);
  const factors = [2,1,2,1,2,1,2,1,2,1];
  let step1 = [];
  a.forEach((b, index) => {
    const c = parseInt(b) * factors[index];
    step1.push(c);
  })
  //console.log('step1: products of 10digits * weight factor =', step1);
  
  const step2 = step1.reduce((i, j) => {
    //console.log('i=', i, ' j=', j)
    if (j > 9) {
      const x = j.toString().split('');
      let sumOfX = 0;
      x.forEach((y) => sumOfX = sumOfX + parseInt(y));
      //console.log('x', sumOfX)
      return i + sumOfX;
    } else {
      return i + j;
    }
  }, 0);
  //console.log('step2: sum of products =', step2);

  if (step2 % 10 === 0) {
    // sum last digit is 0
    //console.log('sum ends in 0, thus check digit is 0');
    return 0;
  } else {
    const nextHigherNumber = ~~((step2 + 9) / 10) * 10;
    //console.log('next higher number', nextHigherNumber);
    return nextHigherNumber - step2;
  }
}

const isFlanValid = (input) => {
  // NNNNNNNNNN N
  const pattern = /^(\d{10})(\d{1})$/;

  let string = input;

  if (string.match(pattern)) {
    const first_group = string.match(pattern)[1];
    //console.log('first_group', first_group);

    const ref_check_digit = string.match(pattern)[2];
    const check_digit = getFlanCheckDigit(first_group);
    //console.log('ref_check_digit', ref_check_digit);
    if (check_digit !== parseInt(ref_check_digit)) {
      // ERR: Invalid check digit
      //console.log('err', `invalid check digit; should be ${check_digit}`);
      return false
    } else {
      return true;
    }
  } else {
    // Err: Invalid pattern
    //console.log('err', 'invalid pattern');
    return false;
  }
}

const isAIAValid = (input) => {
  let val = input.split('');
  
  if(val[0] == 4 && val[1] != 1){
      return false;
  }
  
  if(val[0] == 9 || val[0] == 0){
    if(val[1] != 0 || val[1] != 1 || val[1] != 5 ){
      return false;
    }else{
      if(val.length != 13){
        return false;
      }
    }
  }

  return true;

}


export default {
  isBplacValid,
  isPalValid,
  isMamtcValid,
  isTfsValid,
  isPioneerLifeValid,
  isNorkisValid,
  isSmdcValid,
  isFlanValid,
  isAIAValid
}