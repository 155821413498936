import React from 'react'

const TermsContent = ({ onScrollHandler }) => {
  return (
    <div className="relative bg-transparent overflow-hidden overflow-y-scroll terms-content" onScroll={(e) => onScrollHandler(e)}>
      <div className="w-full py-8 px-6 text-justify">
        <h1 className="text-xl md:text-2xl font-bold leading-5 text-gray-800 mb-4">Terms and Conditions</h1>
        <h4 className="text-md md:text-lg font-semibold text-gray-900 leading-5 mb-6">Electronic Enrollment under BPI’S BIZLINK Automatic Debit Arrangement</h4>
        <p className="text-sm text-gray-700 leading-6 mb-3">The Customer hereby applies with the BANK OF THE PHILIPPINE ISLANDS (the “BANK” or “BPI”) for electronic enrollment under BPI’s Bizlink Automatic Debit Arrangement (the “Facility”) for the payment of his billing(s) via automatic debit against the eligible CA/SA Deposit Account to be nominated by the Customer (the “Customer Account”). For purposes of effecting payments to the Company/Merchant through the Bizlink System, the Customer agrees to the following terms and conditions:
        </p>
        <ol className="list-decimal list-inside">
          <li className="text-sm text-gray-700 leading-6 mb-3">Abide by and comply with the BANK’s rules and regulations as well as terms and conditions governing the BANK’s Products, Services, Facilities and Channels, the Customer Account and the use of the Facility.</li>
          <li className="text-sm text-gray-700 leading-6 mb-3">Authorize the Company/Merchant and/or the BANK to debit or cause the debiting, from time to time, of the Customer Account for amounts due to the Company/Merchant and, thereafter, to remit the same to the Company/Merchant.</li>
          <li className="text-sm text-gray-700 leading-6 mb-3">Authorize the BANK, its subsidiaries, affiliates, its respective directors, stockholders, officers, employees, representatives, agents, service providers and such other persons or entities engaged by the BANK, whether within or outside the Philippines, to disclose and share information to the Company/Merchant in relation to the existence and status of the Customer Account, enrollment to the Facility, cancellation/termination of such enrollment, reason for debit rejection, or to respond to complaint(s) filed or inquiries made by the Customer, insofar as such disclosure or sharing of information may be required or necessary to enable the BANK to perform its contractual obligations under or in connection with the delivery or implementation of the Facility.</li>
          <li className="text-sm text-gray-700 leading-6 mb-3">Agree and consent, without need of notice, to the collection, recording, organization, storage, updating, retrieval, consultation, use, consolidation, blocking, erasure or destruction, sharing and disclosure (collectively, “process” or “processing”) of all information relating to the undersigned, in connection with, or arising from the Facility, by any or all of the following: (i) the BANK, its subsidiaries, affiliates and related interests, whether organized in the Philippines or in other jurisdictions; (ii) the Company/Merchant, to enable it to perform the authorities and exercise the rights granted to it hereunder; (iii) credit information/investigation companies, financial institutions, credit bureaus (including but not limited to, the Credit Information Corporation (CIC), pursuant to Republic Act No. 9510 and its implementing rules and regulations), loyalty program partners, consumer reporting or reference agencies, whether based in the Philippines or other jurisdictions; (iv) government regulatory agencies/bodies having authority or jurisdiction over the BANK, its subsidiaries, affiliates and related interests; (v) entities engaged by the BANK, its subsidiaries, affiliates and related interests to facilitate administration, servicing, and implementation of the Facility, as well as other BANK products, services, facilities and channels; and (vi) such other persons or entities that the BANK may deem as having authority or right to such information, as and when required by the circumstances.</li>
          <li className="text-sm text-gray-700 leading-6 mb-3">Warrant that prior to providing the BANK any information (including personal data) of any individual, as the case may be, he has obtained all necessary authorizations and consents as may be required by applicable confidentiality and data privacy laws.</li>
          <li className="text-sm text-gray-700 leading-6 mb-3">Undertake to ensure that the Customer Account shall always have sufficient and free funds to cover all amounts due as billed by Company/Merchant. Any discrepancy between the billed amount and the debited amount shall be resolved entirely between the Customer and Company/Merchant.</li>
          <li className="text-sm text-gray-700 leading-6 mb-3">Acknowledge that billing and other complaints regarding the merchandise and/or services of the Company/Merchant shall be the direct responsibility of Company/Merchant and that the BANK shall not, in any manner, have any obligation to the Customer for matters relating to such complaints.</li>
          <li className="text-sm text-gray-700 leading-6 mb-3">Agree that his enrollment under the Facility shall automatically be deemed withdrawn/terminated/cancelled at any time the BANK is unable to debit the amount due by reason of closure of his Customer Account, insufficiency of funds, garnishment of deposit, or grounds analogous to the foregoing causes. The Customer may terminate his enrollment in the Facility by submitting a written notice of termination/cancellation to the BANK. The Customer further agrees that in case his enrollment in the Facility is withdrawn/terminated/cancelled, his enrollment may only be reinstated upon the prior approval of the Company/Merchant and the BANK subject to the same terms and conditions for new enrollees.</li>
          <li className="text-sm text-gray-700 leading-6 mb-3">(For Individual Customers) For joint accounts, the Customer agrees that all transactions to be made by any of the co-depositors in connection with the Facility are done with the full knowledge and consent of the other.</li>
          <li className="text-sm text-gray-700 leading-6 mb-3">Recognize that the use of the Facility will help expedite payments of due obligations of the Customer to the Company/Merchant and is for the benefit of the Customer and that of the Company/Merchant.</li>
          <li className="text-sm text-gray-700 leading-6 mb-3">To enable the BANK to perform its contractual obligations under the Facility, the Customer hereby agrees to waive his rights under the confidentiality and data privacy laws of the Philippines and other jurisdictions, including but without limitation, the provisions of RA No. 1405 ( The Law on Secrecy of Bank Deposits), RA No. 6426 ( The Foreign Currency Deposit Act), RA No. 10173 (Data Privacy Act of 2012) and such other applicable confidentiality and data privacy laws including all subsequent amendments or supplements thereto ( collectively, the “Bank Secrecy and Privacy Laws”) and agrees to indemnify and hold the BANK free and harmless from any and all liabilities, suits, damages, claims of whatever kind and nature, expenses, fees and costs that may arise from, or in connection with, the processing of account information maintained with the BANK and implementation of the Facility.</li>
          <li className="text-sm text-gray-700 leading-6 mb-3">Agree and consent that the BANK will not be responsible or liable for any delay, non-performance or failure to perform any of its obligations under this Facility for reasons due to, arising out of or caused, directly or indirectly, by circumstances beyond its reasonable control, including but without limitation, acts of God, natural disasters, calamities, earthquakes, fire, floods, wars, civil or military disturbances, sabotage, acts of terrorism, terrorist attacks, criminal acts or willful misconduct by a party other than the BANK, its officers or employees, epidemics, riots, interruptions, loss or malfunction of utilities, facilities, power outages, electrical or mechanical failure, computer hardware or software failure, system failure or system downtime, network or telecommunication failure, causes or acts attributable to third persons or parties, labor disputes, strikes, walk-outs, riots, lock-outs, rebellion or acts of civil or military authority or governmental actions. </li>
          <li className="text-sm text-gray-700 leading-6 mb-3">Hold the BANK, its subsidiaries and affiliates, and their respective directors, officers, employees, representatives, agents, service providers and such other persons or entities engaged by the BANK, whether within or outside the Philippines, free and harmless and indemnified from and against any liability, costs, damages, claims, losses or dispute which may arise in connection with the implementation of this Facility unless the BANK acts with evident bad faith and gross negligence.</li>
        </ol>
        <p className="text-sm text-gray-700 leading-6 mb-5">For inquiries and comments, please send us a message through <a className="font-medium text-blue-500" href="https://www.bpi.com.ph/about-bpi/contact-us" rel="noopener noreferrer">www.bpi.com.ph/about-bpi/contact-us</a> or call our 24-hour BPI Contact Center at <a className="font-medium text-blue-500" href="tel:+63288010000" rel="noopner noreferrer">(+632) 889-10000</a>. Regulated by the Bangko Sentral ng Pilipinas with email address at <a className="font-medium text-blue-500" href="mailto:consumeraffairs@bsp.gov.ph" rel="noopener noreferrer">consumeraffairs@bsp.gov.ph</a>.</p>
        <p className="text-sm text-gray-500 italic leading-6">Revised 09/03/2020</p>
      </div>
    </div>
  );
}

export default TermsContent;