import Link from "next/link";

const Footer = () => {
  return (
    <footer className="static bottom-0 left-0 right-0 bg-white">
      <div className="mx-auto max-w-6xl px-6 py-8 flex">
        <div className="flex justify-center flex-col sm:flex-row sm:space-x-2 w-full">
          <span className="text-center text-13 leading-5 text-gray-500">Copyright &copy; 2023 BPI.</span>
          <span className="hidden text-center text-13 leading-5 text-gray-500 sm:inline-block">|</span>
          <div className="flex flex-row justify-center space-x-2">
            <Link href="https://www.bpi.com.ph/personal/bank/digital-banking/online/terms-and-conditions" passHref>
              <a target="_blank" className="text-center text-13 leading-5 text-gray-500 hover:text-brand-500">
                <span>Terms and Conditions</span>
              </a>
            </Link>
            <span className="text-center text-13 leading-5 text-gray-500">|</span>
            <Link href="https://bpi.com.ph/personal/bank/digital-banking/privacy-notice" passHref>
              <a target="_blank" className="text-center text-13 leading-5 text-gray-500 hover:text-brand-500">
                <span>Privacy Notice</span>
              </a>
            </Link>
          </div>
        </div>
      </div>
    </footer>
  )
}

export default Footer;