import axios from 'axios';

const getHeaders = () => {
  return {
    'Content-Type': 'application/json',
    Accept: 'application/json',
    Authorization: `Bearer ${process.env.NEXT_PUBLIC_EADA_TOKEN}`,
  };
};

const getCategories = async () => {
  try {
    const response = await axios.get(
      `${process.env.NEXT_PUBLIC_EADA_URL}/categories`,
      { headers: getHeaders() }
    );
    return response;
  } catch (err) {
    if (err.response) {
      return err.response;
    }
    return null;
  }
};

const getBillers = async () => {
  try {
    const response = await axios.get(
      `${process.env.NEXT_PUBLIC_EADA_URL}/billers`,
      { headers: getHeaders() }
    );
    return response;
  } catch (err) {
    if (err.response) {
      return err.response;
    }
    return null;
  }
};

const getBiller = async (biller_id) => {
  try {
    const response = await axios.get(
      `${process.env.NEXT_PUBLIC_EADA_URL}/billers/${biller_id}`,
      { headers: getHeaders() }
    );
    return response;
  } catch (err) {
    if (err.response) {
      return err.response;
    }
    return null;
  }
}

const createEnrolment = async (biller_id, reference_number) => {
  try {
    const response = await axios.post(
      `${process.env.NEXT_PUBLIC_EADA_URL}/enrolments`,
      {
        biller_uuid: biller_id,
        reference_id: reference_number
      },
      { headers: getHeaders() }
    );
    return response;
  } catch (err) {
    console.log('err', err);
    if (err.response) {
      return err.response;
    }
    return null;
  }
}

const getEnrolment = async (enrolment_id, customer_id, bank) => {
  try {
    const response = await axios.get(
      `${process.env.NEXT_PUBLIC_EADA_URL}/enrolments/${enrolment_id}/customer/${customer_id}/bank/${bank}`,
      { headers: getHeaders() }
    );
    return response;
  } catch (err) {
    if (err.response) {
      return err.response;
    }
    return null;
  }
}

const charge = async (enrolment_id, account_id, account_type, account_name, credit_account, mode) => {
  try {
    let params = {
      token: account_id,
      account_type: account_type,
      account_name: account_name
    };
    if (credit_account) {
      params = {
        ...params,
        credit_account: credit_account,
      };
    }
    if (mode) {
      params = {
        ...params,
        mode: mode,
      }
    }
    const response = await axios.post(
      `${process.env.NEXT_PUBLIC_EADA_URL}/enrolments/charge/${enrolment_id}`,
      params,
      { headers: getHeaders() }
    );
    return response;
  } catch (err) {
    if (err.response) {
      return err.response;
    }
    return null;
  }
}

const verify = async (charge_id, pin, confirmationId) => {
  try {
    const response = await axios.post(
      `${process.env.NEXT_PUBLIC_EADA_URL}/enrolments/${charge_id}/verify`,
      {
        otp: pin,
        confirmation_id: confirmationId
      },
      { headers: getHeaders() }
    );
    return response;
  } catch (err) {
    if (err.response) {
      return err.response;
    }
    return null;
  }
}

const saveEnrolmentId = (id) => {
  try {
    window.sessionStorage.setItem('enrolment', id);
    return true;
  } catch (err) {
    console.log(err);
  }
  return false;
}

const getEnrolmentId =  () => {
  try {
    const id = window.sessionStorage.getItem('enrolment');
    return id;
  } catch (err) {
    console.log(err);
  }
  return null;
}

const clearSession = async () => {
  try {
    window.sessionStorage.clear();
  } catch (err) {
    console.log(err);
  }
}

export default {
  getCategories,
  getBillers,
  getBiller,
  saveEnrolmentId,
  getEnrolmentId,
  clearSession,
  createEnrolment,
  getEnrolment,
  charge,
  verify,
};