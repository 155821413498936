import React from 'react'

const BpiCcTermsContent = ({ onScrollHandler }) => {
  return (
    <div className="relative bg-transparent overflow-hidden overflow-y-scroll terms-content" onScroll={(e) => onScrollHandler(e)}>
      <div className="w-full py-8 px-6 text-justify">
        <h1 className="text-xl md:text-2xl font-bold leading-5 text-gray-800 mb-4">Terms and Conditions</h1>
        <h4 className="text-md md:text-lg font-semibold text-gray-900 leading-5 mb-6">Electronic Enrollment to BPI’s Automatic Debit Arrangement for the Payment of BPI Credit Cards</h4>
        <p className="text-sm text-gray-700 leading-6 mb-3">The Cardholder hereby applies with the BANK OF THE PHILIPPINE ISLANDS / BPI FAMILY SAVINGS BANK (the “BANK” or “BPI”) for electronic enrollment to BPI’s Automatic Debit Arrangement facility (the “Facility”) where the payment of his/her BPI Credit Card account(s) billings shall be effected via automatic debit against his/her eligible nominated BPI CA/SA Deposit Account (the “Deposit Account”).
        </p>
        <p className="text-sm text-gray-700 leading-6 mb-3">For purposes of effecting payments of his/her BPI Credit Card account(s) through the Facility, the Cardholder agrees to be bound by the following terms and conditions:
        </p>
        <ol className="list-decimal list-inside">
          <li className="text-sm text-gray-700 leading-6 mb-3">Upon approval of the enrollment, the BANK is authorized to debit or cause the debiting, from time to time, but primarily on the payment due date(s) indicated in the Statement(s) of Account, of the Deposit Account for amounts due to the BANK for payment of Cardholder’s BPI Credit Card account(s). The BANK has the absolute discretion, without giving any reason or notice, to decline, reject, or cancel any enrollment to the Facility.</li>
          <li className="text-sm text-gray-700 leading-6 mb-3">The BANK, its subsidiaries, and affiliates, as well as their respective directors, officers, employees, representatives, agents, and service providers, whether within or outside the Philippines, are authorized to disclose and share information of the Cardholder, insofar as such disclosure or sharing of information may be required or necessary to enable the BANK to perform its contractual obligations under or in connection with the delivery or implementation of the Facility, including for purposes of enrollment to the Facility, cancellation/termination of such enrollment, and responding to complaint(s) filed or inquiries made by the Cardholder.</li>
          <li className="text-sm text-gray-700 leading-6 mb-3">The Cardholder represents and warrants that prior to providing the BANK any information (including personal data) of any individual, he/she has obtained all necessary authorizations and consents as may be required by applicable confidentiality and data privacy laws.</li>
          <li className="text-sm text-gray-700 leading-6 mb-3">The Cardholder understands that the amount to be debited from the Deposit Account shall be the Minimum Amount Due (MAD) or the Total Outstanding Balance (TOB), as applied under this Facility, on the payment due date(s) indicated in the Cardholder’s Statement(s) of Account.</li>
          <li className="text-sm text-gray-700 leading-6 mb-3">The Cardholder agrees that once he/she enrolls the payment of his/her BPI Credit Card account(s) in the Facility, he/she, likewise, automatically enrolls the payment of his/her other linked BPI Credit Card account(s) such as, but not limited to, his/her other principal and supplementary/extension accounts, without need of further notice, act, instrument, or deed.</li>
          <li className="text-sm text-gray-700 leading-6 mb-3">The Cardholder undertakes to ensure that the Deposit Account shall always have sufficient and cleared funds to cover all amounts due on his/her BPI Credit Card account(s) as billed by the BANK.</li>
          <li className="text-sm text-gray-700 leading-6 mb-3">The Cardholder understands that if the available withdrawable balance of his/her Deposit Account is insufficient to cover the MAD or TOB on due date, the BANK may, at its sole option, not debit said balance in the Deposit Account. In such a case, the Cardholder shall continue to be liable for the payment of his/her BPI Credit Card account(s)’ obligations and shall be responsible for making the necessary arrangement to settle his/her obligations through any of the BANK’s other payment channels, and further acknowledges that his/her enrolled BPI Credit Cards may incur applicable fees and charges until fully paid.</li>
          <li className="text-sm text-gray-700 leading-6 mb-3">The Cardholder may terminate his/her enrollment in the Facility by submitting a written notice of termination/cancellation to the BANK.</li>
          <li className="text-sm text-gray-700 leading-6 mb-3">The Cardholder further agrees that in case his/her enrollment in the Facility is withdrawn/terminated/cancelled for whatever reason, his/her enrollment may only be reinstated upon the prior approval of the BANK subject to the same terms and conditions for new enrollees.</li>
          <li className="text-sm text-gray-700 leading-6 mb-3">The BANK reserves the right disallow enrollment of joint deposit accounts to the Facility. For joint Deposit Account that is accepted for enrollment, the Cardholder represents and warrants that all transactions made or to be made in connection with the Facility are done with the full knowledge and consent of the other co-depositors.</li>
          <li className="text-sm text-gray-700 leading-6 mb-3">The Cardholder recognizes that the use of the Facility will help expedite payments of due obligations of the Cardholder on his/her BPI Credit Card account(s) and is for the benefit of the Cardholder and the BANK.</li>
          <li className="text-sm text-gray-700 leading-6 mb-3">In case a payment due date falls on a Saturday, Sunday or a regular or non-working holiday, the debiting of the Deposit Account for amounts due to the BANK for payment of Cardholder’s BPI Credit Card account(s) shall be moved to the next business day.</li>
          <li className="text-sm text-gray-700 leading-6 mb-3">The Cardholder agrees and consents that the BANK will not be responsible or liable for any delay, non-performance or failure to perform any of its obligations under this Facility for reasons due to, arising out of or caused, directly or indirectly, by circumstances beyond its reasonable control, including but without limitation, acts of God, natural disasters, calamities, earthquakes, fire, floods, wars, civil or military disturbances, sabotage, acts of terrorism, terrorist attacks, criminal acts or willful misconduct by a party other than the BANK, its officers or employees, epidemics, pandemics, riots, interruptions, loss or malfunction of utilities, facilities, power outages, electrical or mechanical failure, computer hardware or software failure, system failure or system downtime, network or telecommunication failure, causes or acts attributable to third persons or parties, labor disputes, strikes, walk-outs, riots, lock-outs, rebellion or acts of civil or military authority or governmental actions.</li>
          <li className="text-sm text-gray-700 leading-6 mb-3">The Cardholder agrees to hold the BANK, its subsidiaries and affiliates, and their respective directors, officers, employees, representatives, agents, and service providers, whether within or outside the Philippines, free and harmless and indemnified from and against any liability, costs, damages, claims, losses or dispute which may arise in connection with the implementation of this Facility, unless the BANK acts with evident bad faith and gross negligence.</li>
          <li className="text-sm text-gray-700 leading-6 mb-3">The BANK may, at any time and for whatever reason it may deem proper, amend, revise or modify these Terms and Conditions, and any such amendments, revisions, or modifications shall bind the Cardholder upon notice or on the date of effectivity as specified in the notice, whichever is earlier. If the Cardholder objects or is not amenable to any such amendments, revisions or modifications, the Cardholder agrees to cancel his/her enrollment to the Facility by sending a written notice to the BANK within sixty (60) calendar days from notice of amendment, revision, or modification. Failure to notify the BANK of Cardholder’s intention to cancel his/her enrollment to the Facility shall be construed as acceptance by the Cardholder of any such amendments, revisions or modifications to these Terms and Conditions. </li>
          <li className="text-sm text-gray-700 leading-6 mb-3">These Terms and Conditions are supplemented by the Terms and Conditions Governing the Issuance and Use of BPI Credit Cards, the BANK’s terms and conditions governing the BANK’s Products, Services, Facilities and Channels, the Deposit Account terms and conditions, the BPI Online Banking Services Terms and Conditions, and other terms and conditions governing the use of the Facility, all of which are made integral parts hereof by reference and shall be resorted to in instances where they are applicable.</li>
        </ol>
        <p className="text-sm text-gray-700 leading-6 mb-5">For inquiries and comments, please send us a message through <a className="font-medium text-blue-500" href="https://www.bpi.com.ph/about-bpi/contact-us" rel="noopener noreferrer">www.bpi.com.ph/about-bpi/contact-us</a> or call our 24-hour BPI Contact Center at <a className="font-medium text-blue-500" href="tel:+63288010000" rel="noopner noreferrer">(+632) 889-10000</a>. Regulated by the Bangko Sentral ng Pilipinas with email address at <a className="font-medium text-blue-500" href="mailto:consumeraffairs@bsp.gov.ph" rel="noopener noreferrer">consumeraffairs@bsp.gov.ph</a>.</p>
        <p className="text-sm text-gray-500 italic leading-6">Updated as of September 2021</p>
      </div>
    </div>
  );
}

export default BpiCcTermsContent;