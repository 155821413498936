import React, { useState, useEffect } from 'react'
import Modal from 'react-modal'
import cn from 'classnames'

const Step4Modal = ({ visible, onClose, biller, referenceNumber, mobileNumber, otp, loading, error, onActionClick }) => {
  Modal.setAppElement('#__next');
  const [pin, setPin] = useState('');
  const [complete, setComplete] = useState(false);
  const [isLoading, setIsLoading] = useState(false);

  useEffect(() => {
    setPin(otp);
  }, [otp]);

  useEffect(() => {
    setIsLoading(loading);
  }, [loading]);

  const onPinChange = (e) => {
    const value = e.target.value.replace(/[^0-9]/g, '');
    setPin(value);
    if (value.length === 6) {
      setComplete(true);
    } else {
      setComplete(false);
    }
  }

  const onSubmitClick = (e) => {
    e.preventDefault();
    setIsLoading(true);
    onActionClick({ pin });
  }

  return (
    <Modal
      isOpen={visible}
      className="centered relative w-auto items-center sm:max-w-md m-2 sm:mx-auto my-6 outline-none"
      overlayClassName="fixed inset-0 bg-cool-gray-500 bg-opacity-75 overflow-x-hidden overflow-y-auto"
      onRequestClose={() => onClose()}
      shouldCloseOnOverlayClick={false}
      shouldCloseOnEsc={false}
      closeTimeoutMS={500}
    >
      <div className="w-full bg-white bg-clip-padding rounded-lg shadow-xl p-6 sm:p-10 outline-none relative">
        <button className="h-auto w-auto p-2 bg-transparent outline-none focus:outline-none absolute top-1 right-1 text-gray-400 hover:text-gray-600 transition-opacity duration-300 ease-in" onClick={() => onClose()}>
          <svg className="w-6 h-6" fill="currentColor" viewBox="0 0 20 20" xmlns="http://www.w3.org/2000/svg"><path fillRule="evenodd" d="M4.293 4.293a1 1 0 011.414 0L10 8.586l4.293-4.293a1 1 0 111.414 1.414L11.414 10l4.293 4.293a1 1 0 01-1.414 1.414L10 11.414l-4.293 4.293a1 1 0 01-1.414-1.414L8.586 10 4.293 5.707a1 1 0 010-1.414z" clipRule="evenodd"></path></svg>
        </button>
        
        <div>
          <div className="flex items-center">
            <img className="h-12 w-12 rounded-full border border-gray-200 shadow-sm overflow-hidden" src={biller !== undefined ? biller.image : ''} alt={biller !== undefined ? biller.name : ''} />
            <div className="pl-3 flex-1">
              <p className="leading-5 text-gray-700 font-medium">
                {biller !== undefined ? biller.name : ''}
              </p>
              <p className="leading-4 text-gray-500 text-xs">
                {`Reference number: ${referenceNumber || ''}`}
              </p>
            </div>
          </div>
          <div className="mt-8">
            <p className="text-gray-700 font-medium">One-Time PIN</p>
            <p className="mt-1 text-gray-500 text-sm">{ !!mobileNumber ? 'Enter the one-time PIN sent to:' : 'Enter the one-time PIN sent to your registered mobile'}</p>
            { !!mobileNumber && <p className="text-xl text-gray-800 font-bold leading-8">{mobileNumber}</p> }
            <div className="mt-4 relative rounded-md shadow-sm">
              <input id="otp" className={cn("modal-input py-2 px-3 form-input block w-full sm:text-sm sm:leading-5 rounded border border-gray-200 focus:outline-none focus:border-blue-300 focus:ring focus:ring-blue-200 focus:ring-opacity-50 text-center tracking-widest", { 'border-danger': !!error })} placeholder="" maxLength={6} inputMode="numeric" value={pin} onChange={(e) => onPinChange(e)} />
            </div>
          </div>
        </div>
        
        <div className="mt-6 sm:mt-10">
          <p className="w-full text-sm text-danger font-medium text-center mb-1">{error}</p>
          <span className="flex w-full rounded-md shadow-sm ">
            <button disabled={!complete || isLoading} onClick={(e) => onSubmitClick(e)} type="button" className={cn("inline-flex items-center justify-center w-full rounded-md border border-transparent px-6 py-3 bg-brand-500 text-base leading-6 font-medium text-white shadow-sm hover:bg-brand-700 focus:outline-none focus:border-brand-700 focus:shadow-outline-red transition ease-in-out duration-150", { 'opacity-50': !complete || isLoading, 'cursor-not-allowed': !complete || isLoading, 'hover:bg-brand-700': complete || !isLoading})}>
              { isLoading && <svg className="animate-spin -ml-1 mr-3 h-4 w-4 text-white" xmlns="http://www.w3.org/2000/svg" fill="none" viewBox="0 0 24 24">
                <circle className="opacity-25" cx="12" cy="12" r="10" stroke="currentColor" strokeWidth="4"></circle>
                <path className="opacity-75" fill="currentColor" d="M4 12a8 8 0 018-8V0C5.373 0 0 5.373 0 12h4zm2 5.291A7.962 7.962 0 014 12H0c0 3.042 1.135 5.824 3 7.938l3-2.647z"></path>
              </svg>
              }
              { isLoading ? 'Verifying' : 'Verify' }
            </button>
          </span>
        </div>
      </div>
    </Modal>
  );
}

export default Step4Modal;