import React, { useState, useEffect } from 'react'
import Modal from 'react-modal'
import cn from 'classnames'

const Step3Modal = ({ visible, onClose, biller, referenceNumber, mobileNumber, loading, error, onActionClick }) => {
  Modal.setAppElement('#__next');
  const [phone, setPhone] = useState('');
  const [isLoading, setIsLoading] = useState(false);

  useEffect(() => {
    setPhone(mobileNumber);
  }, [mobileNumber])

  useEffect(() => {
    setIsLoading(loading);
  }, [loading])

  const onSendClick = (e) => {
    e.preventDefault();
    setIsLoading(true);
    onActionClick();
  }

 
  return (
    <Modal
      isOpen={visible}
      className="centered relative w-auto items-center sm:max-w-md m-2 sm:mx-auto my-6 outline-none"
      overlayClassName="fixed inset-0 bg-cool-gray-500 bg-opacity-75 overflow-x-hidden overflow-y-auto"
      onRequestClose={() => onClose()}
      shouldCloseOnOverlayClick={false}
      shouldCloseOnEsc={false}
      closeTimeoutMS={500}
    >
      <div className="w-full bg-white bg-clip-padding rounded-lg shadow-xl p-6 sm:p-10 outline-none relative">
        <button className="h-auto w-auto p-2 bg-transparent outline-none focus:outline-none absolute top-1 right-1 text-gray-400 hover:text-gray-600 transition-opacity duration-300 ease-in" onClick={() => onClose()}>
          <svg className="w-6 h-6" fill="currentColor" viewBox="0 0 20 20" xmlns="http://www.w3.org/2000/svg"><path fillRule="evenodd" d="M4.293 4.293a1 1 0 011.414 0L10 8.586l4.293-4.293a1 1 0 111.414 1.414L11.414 10l4.293 4.293a1 1 0 01-1.414 1.414L10 11.414l-4.293 4.293a1 1 0 01-1.414-1.414L8.586 10 4.293 5.707a1 1 0 010-1.414z" clipRule="evenodd"></path></svg>
        </button>
        
        <div>
          <div className="flex items-center">
            <img className="h-12 w-12 rounded-full border border-gray-200 shadow-sm overflow-hidden" src={biller !== undefined ? biller.image : ''} alt={biller !== undefined ? biller.name : ''} />
            <div className="pl-3 flex-1">
              <p className="leading-5 text-gray-700 font-medium">
                {biller !== undefined ? biller.name : ''}
              </p>
              <p className="leading-4 text-gray-500 text-xs">
                {`Reference number: ${referenceNumber || ''}`}
              </p>
            </div>
          </div>
          <div className="mt-8">
            <p className="text-gray-700 font-medium">One-Time PIN</p>
            <p className="text-gray-500 text-sm">
              To continue, we will be sending a one-time password to your registered mobile phone.
            </p>
            {
              !!phone && !!phone && <div className="mt-2">
                <p className="text-xl text-gray-800 font-bold leading-8 mb-1">{phone}</p>
              </div>
            }
          </div>
        </div>
        
        <div className="mt-10">
          <p className="w-full text-sm text-danger font-medium text-center mb-1">{error}</p>
          <div className="sm:flex sm:items-center">
            <button disabled={isLoading} onClick={(e) => onSendClick(e)} type="button" className={cn("block sm:inline-flex items-center justify-center w-full rounded-md border border-transparent px-3 py-3 bg-brand-500 text-base leading-6 font-medium text-white shadow-sm focus:outline-none focus:border-brand-700 focus:shadow-outline-red transition ease-in-out duration-150", { 'opacity-50': isLoading, 'cursor-not-allowed': isLoading, 'hover:bg-brand-700': !isLoading})}>
              { isLoading && <svg className="inline-flex animate-spin -ml-1 mr-3 h-4 w-4 text-white" xmlns="http://www.w3.org/2000/svg" fill="none" viewBox="0 0 24 24">
                <circle className="opacity-25" cx="12" cy="12" r="10" stroke="currentColor" strokeWidth="4"></circle>
                <path className="opacity-75" fill="currentColor" d="M4 12a8 8 0 018-8V0C5.373 0 0 5.373 0 12h4zm2 5.291A7.962 7.962 0 014 12H0c0 3.042 1.135 5.824 3 7.938l3-2.647z"></path>
              </svg>
              }
              { isLoading ? 'Processing' : 'Yes, send me the code' }
            </button>
            <button disabled={isLoading} onClick={() => onClose()} type="button" className={cn("block sm:inline-flex justify-center w-full sm:w-auto mt-2 sm:mt-0 sm:ml-4 rounded-md border border-transparent px-10 py-3 bg-cool-gray-200 text-base leading-6 font-medium text-gray-700 shadow-sm focus:outline-none focus:shadow-outline-gray transition ease-in-out duration-150", { 'opacity-50': isLoading, 'cursor-not-allowed': isLoading, 'hover:bg-cool-gray-300': !isLoading})}>
              Cancel
            </button>
          </div>
        </div>
      </div>
    </Modal>
  );
}

export default Step3Modal;