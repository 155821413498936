import React, { useState, useEffect } from 'react'
import Head from 'next/head'
import Link from 'next/link'
import { useRouter } from 'next/router'
import cx from "classnames";
import * as Sentry from "@sentry/nextjs";

import CategoryTabs from '../components/categoryTabs'
import BillerList from '../components/billerList'
import TermsModal from '../components/termsModal'
import Step1Modal from '../components/step1Modal'
import Step2Modal from '../components/step2Modal'
import Step3Modal from '../components/step3Modal'
import Step4Modal from '../components/step4Modal'
import Step5Modal from '../components/step5Modal'
import LoadingModal from '../components/loadingModal'
import ErrorModal from '../components/errorModal'
import BpiCcStep1Modal from '../components/bpiCcStep1Modal'
import BpiCcStep2Modal from '../components/bpiCcStep2Modal'
import BpiCcStep3Modal from '../components/bpiCcStep3Modal'
import BpiCcStep4Modal from '../components/bpiCcStep4Modal'
import BpiCcStep5Modal from '../components/bpiCcStep5Modal'
import Footer from '../components/footer';

import Magpie from '../api/Magpie'

import { isBrowser, isMobile } from 'react-device-detect';


export async function getServerSideProps() {
  const res = await Magpie.getCategories();
  const res2 = await Magpie.getBillers();

  const categoriesData = res.data;
  const billersData = res2.data;

  return {
    props: {
      categoriesData,
      billersData,
    },
  }
}

export const BPICC_CODE = 'BPICC';

const Home = ({ categoriesData, billersData }) => {
  const router = useRouter();
  const [isOpen, setIsOpen] = useState(false);
  const [selectedTab, setSelectedTab] = useState(0);
  const [categories, setCategories] = useState([]);
  const [ayalaCategory, setAyalaCategory] = useState(undefined);
  const [selectedBiller, setSelectedBiller] = useState(undefined);
  const [billerData, setBillerData] = useState([]);
  const [billers, setBillers] = useState([]);
  const [allBillers, setAllBillers] = useState([]);
  const [currentStep, setCurrentStep] = useState(1);
  const [userAgree, setUserAgree] = useState(false);
  const [referenceNumber, setReferenceNumber] = useState('');
  const [accounts, setAccounts] = useState([]);
  const [account, setAccount] = useState(undefined);
  const [mobileNumber, setMobileNumber] = useState('');
  const [charge, setCharge] = useState(null);
  const [otp, setOtp] = useState('');
  const [loading, setLoading] = useState(false);
  const [error, setError] = useState('');

  const [creditAccounts, setCreditAccounts] = useState([]);
  const [creditAccount, setCreditAccount] = useState(undefined);
  const [creditPaymentMode, setCreditPaymentMode] = useState(undefined);
 
  const [showTermsModal, setShowTermsModal] = useState(false);
  const [showStep1Modal, setShowStep1Modal] = useState(false);
  const [showStep2Modal, setShowStep2Modal] = useState(false);
  const [showStep3Modal, setShowStep3Modal] = useState(false);
  const [showStep4Modal, setShowStep4Modal] = useState(false);
  const [showStep5Modal, setShowStep5Modal] = useState(false);
  const [showLoadingModal, setShowLoadingModal] = useState(false);
  const [showErrorModal, setShowErrorModal] = useState(false);

  const [showCcStep1Modal, setShowCcStep1Modal] = useState(false);
  const [showCcStep2Modal, setShowCcStep2Modal] = useState(false);
  const [showCcStep3Modal, setShowCcStep3Modal] = useState(false);
  const [showCcStep4Modal, setShowCcStep4Modal] = useState(false);
  const [showCcStep5Modal, setShowCcStep5Modal] = useState(false);

  const [selectedCategory, setSelectedCategory] = useState("");
  const [showCategory, setShowCategory] = useState(true);
  const ayalaLandProperties = "Ayala Land Properties";

  const checkCategory = (cat) => {
    try {
      if(cat.includes("Others")){
        return true;
      }

      const intersection = categories.filter(element => cat.includes(element.category));
      if(intersection.length < 1){
        if(!cat.includes(ayalaLandProperties)){
            
          }else{
            return false;
          }
      }else{
        return false;
      }
    } catch (e) {
      Sentry.captureException(e);
    }
  }

  const handleSelectCategory = async (categoryId) => {
    try {
      var temp_category = categoryId;
      if (categoryId == "back.real.estate") {
        categoryId = "Real Estate";
      }

      if (categoryId == 'back') {
        setSelectedCategory(null);
        setShowCategory(!showCategory);
        if ('data' in billersData) {
          const { data } = billersData;
          setBillerData(data.sort((a, b) => a.name.localeCompare(b.name)));
          setBillers(data.sort((a, b) => a.name.localeCompare(b.name)));
        }
        return null;
      }

      if (categoryId == 'All') {
        setShowCategory(!showCategory);
        setSelectedCategory(categoryId);
        if ('data' in billersData) {
          const { data } = billersData;
          // setBillerData(data.sort((a, b) => a.name.localeCompare(b.name)));
          setBillers(data.sort((a, b) => a.name.localeCompare(b.name)));
        }
        return null;
      }

      // checkCategory(["Water"]);
      
      if (categoryId == 'Others') {
        // setBillerData(billers.filter((biller) => checkCategory(biller.categories) == true && biller.categories != null).sort((a, b) => a.name > b.name ? 1 : -1));
        setBillers(billers.filter((biller) => checkCategory(biller.categories) == true && biller.categories != null).sort((a, b) => a.name > b.name ? 1 : -1));
      } else {
        if (categoryId == ayalaLandProperties) {
          setBillers(allBillers.filter((biller) => biller.categories.includes(categoryId) && biller.categories != null).sort((a, b) => a.name > b.name ? 1 : -1));
        } else {
        // setBillerData(billers.filter((biller) => biller.categories.includes(categoryId) && biller.categories != null).sort((a, b) => a.name > b.name ? 1 : -1));
        setBillers(billers.filter((biller) => biller.categories.includes(categoryId) && biller.categories != null).sort((a, b) => a.name > b.name ? 1 : -1));
        }
      }

      setSelectedCategory(categoryId);

      if (categoryId != ayalaLandProperties && temp_category != "back.real.estate") {
        setShowCategory(!showCategory);
      }
    } catch (e) {
      Sentry.captureException(e);
    }
  }

  useEffect(() => {
    const getEnrolmentDetails = async () => {
      const { query } = router;
      //console.log('query', query);
      if ('cid' in query && 'bank' in query) {
        setShowLoadingModal(true);
        const cid = query.cid;
        const bank = query.bank;
        const enrolmentId = Magpie.getEnrolmentId();

        // console.log('enrolment', enrolmentId);
        // console.log('customer', cid);
        // console.log('bank', bank);
        try {
          if (!enrolmentId) {
            // console.log('getEnrolment error', data);
            
            setError("Unable to restore your enrollment session. Please try again using other web browsers like Google Chrome, Safari, Mozilla Firefox, or Microsoft Edge.");
            setShowLoadingModal(false);
            setShowErrorModal(true);

            return;
          }

          const { data } = await Magpie.getEnrolment(enrolmentId, cid, bank);
          // console.log(data)
        
          if (data) {
            if (data.sources) {
              setSelectedBiller(billerData.find(b => b.id == data.description.biller_uuid));
              const billerRes = await Magpie.getBiller(data.description.biller_uuid);
              //console.log('biller', billerRes.data);
              if (billerRes.status === 200) setSelectedBiller(billerRes.data);
              setReferenceNumber(data.description.reference_id);
              setAccounts(data.sources);
              setAccount(data.sources[0]);
              
              if (data.description.reference_id.toUpperCase() === BPICC_CODE) {
                // check if has card accounts
                if (data.card_accounts) {
                  const cardAccts = [];
                  /* data.card_accounts.forEach((c) => {
                    cardAccts.push(c.linkedCards);
                  }); */
                  for (const a of data.card_accounts) {
                    /* for (const b of a.linkedCards) {
                      cardAccts.push(b);
                    } */
                    cardAccts.push(a);
                  }
                  //console.log('cards', cardAccts);
                  setCreditAccounts(cardAccts);
                } else {
                  // console.log('cardAccts error', data.card_accounts_raw);
                  setError(data.card_accounts_raw.body.message);
                  setShowLoadingModal(false);
                  setShowErrorModal(true);
                  return;
                }
              }

              if (data.mobile_number) {
                setMobileNumber(data.mobile_number);
              }
             
              setShowLoadingModal(false);
              setLoading(false);
              if (billerRes.data.code === BPICC_CODE) {
                setCurrentStep(1);
                setShowCcStep1Modal(true);
              } else {
                setCurrentStep(2);
                setShowStep2Modal(true);
              }
              
            } else {
              // console.log('getEnrolment error', data);
              setError("An error occurred while trying to retrieve your accounts. Please try again in few minutes.");
              setShowLoadingModal(false);
              setShowErrorModal(true);
            }
          }
        } catch (err) {
          // console.log('getAccounts error', err);
          Sentry.captureException(err);
          setError('Something went wrong. Please try again.');
          setShowLoadingModal(false);
          setShowErrorModal(true);
        }
      }
    }

    getEnrolmentDetails();
  }, [router])

  useEffect(() => {
    if ('data' in categoriesData) {
      const { data } = categoriesData;
      let cat = [];
      data.forEach((i) => {
        if(i.category != ayalaLandProperties){
        cat.push(i)
        }else{
          setAyalaCategory(i);
        }
      })
      // console.log('categories', cat);
      setCategories(cat);
    }

    if ('data' in billersData) {
      const { data } = billersData;
      setAllBillers(data.sort((a, b) => a.name.localeCompare(b.name)));
      setBillerData(data.sort((a, b) => a.name.localeCompare(b.name)));
      setBillers(data.sort((a, b) => a.name.localeCompare(b.name)));
    }
  }, [])

  const onSearch = (query) => {
    setSelectedTab(0);
    if (query === '') {
      setShowCategory(true);
      setBillers(billerData)
    } else {
      setShowCategory(false);
      setBillers(billerData.filter((biller) => biller.name.toLowerCase().includes(query.toLowerCase())));
    }
  }

  const onSelectCategoryHandler = (categoryId) => {
    setSelectedTab(categoryId);
    if (categories[categoryId] === 'All') {
      setBillers(billerData);
    } else {
      const category = categories[categoryId];
      setBillers(billerData.filter((biller) => biller.categories.includes(category)));
    }
    
  }

  const onSelectBillerHandler = (id) => {
    // console.log('biller', billerData.find(b => b.id == id))
    setSelectedBiller(billerData.find(b => b.id == id));
    setShowTermsModal(true);
  }

  const termsActionHandler = async (agree) => {
    setUserAgree(agree);
    setReferenceNumber('');
    if (selectedBiller.code === BPICC_CODE) {
      setLoading(true);
      await createBpiCcEnrolment(selectedBiller.id, 'bpicc');
    } else {
      setCurrentStep(1);
      setShowTermsModal(false);
      setShowStep1Modal(true);
    }
  }

  const reset = async () => {
    Magpie.clearSession();
    setCurrentStep(1);
    setUserAgree(false);
    setSelectedBiller(undefined);
    setReferenceNumber('');
    setAccounts([]);
    setAccount(undefined);
    setMobileNumber('');
    setCharge(null);
    setOtp('')
    setLoading(false);
    setError('');
    setCreditAccounts([]);
    setCreditAccount(undefined);
    setCreditPaymentMode(undefined);

    router.replace('/');
  }

  const createEnrolment = async (billerId, refNum) => {
    try {
      //console.log('params', billerId, refNum)
      const { data } = await Magpie.createEnrolment(billerId, refNum);
      //console.log('enrolment', data);
      if (data) {
        if (data.id) {
          if (Magpie.saveEnrolmentId(data.id)) {
            setError('');
            setLoading(false);
            setShowStep1Modal(false);
            window.location.href = data.extradata.redirect;
          } else {
            setError('Unable to save your enrolment session. Please try again using other web browsers like Google Chrome, Safari, Mozilla Firefox, or Microsoft Edge.');
          }
        } else {
          if (data.message) {
            setError(data.message);
          } else {
            setError('Something went wrong. Please try again.');
          }
          setLoading(false)
        }
      }
    } catch (err) {
      // console.log('createEnrolment error', err);
      Sentry.captureException(err);
      setError('Something went wrong. Please try again.');
      setLoading(false);
    }
  }

  const createBpiCcEnrolment = async (billerId, refNum) => {
    try {
      //console.log('params', billerId, refNum)
      const { data } = await Magpie.createEnrolment(billerId, refNum);
      //console.log('enrolment', data);
      if (data) {
        if (data.id) {
          if (Magpie.saveEnrolmentId(data.id)) {
            setError('');
            setLoading(false);
            setShowTermsModal(false);
            window.location.href = data.extradata.redirect;
          } else {
            setError('Unable to save your enrolment session. Please try again using other web browsers like Google Chrome, Safari, Mozilla Firefox, or Microsoft Edge.');
          }
        } else {
          if (data.message) {
            setError(data.message);
          } else {
            setError('Something went wrong. Please try again.');
          }
          setLoading(false)
        }
      }
    } catch (err) {
      // console.log('createEnrolment error', err);
      Sentry.captureException(e);
      setError('Something went wrong. Please try again.');
      setLoading(false);
    }
  }

  const createCharge = async () => {
    try {
      const enrolmentId = Magpie.getEnrolmentId();
      if (!enrolmentId) {
        //console.log('getEnrolment error', data);
        setError('Unable to restore your enrollment session. Please try again using other web browsers like Google Chrome, Safari, Mozilla Firefox, or Microsoft Edge.');
      } else {
        // console.log({account})
        // console.log('params', enrolmentId, account.id, account.account_type, account.name)
        const bankAccount = account.bank_account;
        // console.log({bankAccount})
        const { data } = await Magpie.charge(enrolmentId, account.id, bankAccount.account_type, bankAccount.account_name);
        //console.log('charge', data);
        if (data) {
          if (data.id) {
            setCharge(data);
            setError('');
            setLoading(false);
            setCurrentStep(currentStep + 1);
            setShowStep3Modal(false);
            setShowStep4Modal(true);
          } else {
            if (data.message) {
              setError(data.message);
            } else {
              setError('Something went wrong. Please try again.');
            }
            setLoading(false);
          }
        }
      }
    } catch (err) {
      // console.log('createCharge error', err);
      Sentry.captureException(err);
      setError('Something went wrong. Please try again.');
      setLoading(false);
    }
  }

  const createBpiCcCharge = async () => {
    try {
      const enrolmentId = Magpie.getEnrolmentId();
      if (!enrolmentId) {
        //console.log('getEnrolment error', data);
        setError('Unable to restore your enrollment session. Please try again using other web browsers like Google Chrome, Safari, Mozilla Firefox, or Microsoft Edge.');
      } else {
        //console.log('params', enrolmentId, account.id, account.account_type, account.name)
        const bankAccount = account.bank_account;
        // console.log({creditAccount})
        const { data } = await Magpie.charge(enrolmentId, account.id, bankAccount.account_type, bankAccount.account_name, creditAccount.id, creditPaymentMode);
        //console.log('charge', data);
        if (data) {
          if (data.id) {
            setCharge(data);
            setError('');
            setLoading(false);
            setCurrentStep(currentStep + 1);
            setShowCcStep3Modal(false);
            setShowCcStep4Modal(true);
          } else {
            if (data.message) {
              setError(data.message);
            } else {
              setError('Something went wrong. Please try again.');
            }
            setLoading(false);
          }
        }
      }
    } catch (err) {
      // console.log('createCharge error', err);
      Sentry.captureException(err);
      setError('Something went wrong. Please try again.');
      setLoading(false);
    }
  }

  const verifyPin = async (otp) => {
    try {
      //console.log('params', charge.id, otp, charge.confirmation_id.toString());
      const { confirmation_id } = charge.metadata;
      const { data } = await Magpie.verify(charge.id, otp, confirmation_id);
      //console.log('verify', data);
      if (data) {
        if (data.captured) {
          setCharge(data);
          setError('');
          setLoading(false);
          setCurrentStep(currentStep + 1);
          setShowStep4Modal(false);
          setShowStep5Modal(true);
        } else {
          setLoading(false);
          setError('Something went wrong. Please try again.');
        }
      } else {
        setLoading(false);
      }
    } catch (err) {
      console.log('verifyPin error', err);
      Sentry.captureException(err);
      setError('Something went wrong. Please try again.');
      setLoading(false);
    }
  }

  const verifyBpiCcPin = async (otp) => {
    try {
      //console.log('params', charge.id, otp, charge.confirmation_id.toString());
      // console.log({charge})
      // console.log({otp})
      const { confirmation_id } = charge.metadata;
      const { data } = await Magpie.verify(charge.id, otp, confirmation_id);
      // console.log('verify', data);
      if (data) {
        if (data.captured) {
          setCharge(data);
          setError('');
          setLoading(false);
          setCurrentStep(currentStep + 1);
          setShowCcStep4Modal(false);
          setShowCcStep5Modal(true);
        } else {
          setLoading(false);
          setError('Something went wrong. Please try again.');
        }
      } else {
        setLoading(false);
      }
      /* setError('');
      setLoading(false);
      setCurrentStep(currentStep + 1);
      setShowCcStep4Modal(false);
      setShowCcStep5Modal(true); */
    } catch (err) {
      console.log('verifyPin error', err);
      Sentry.captureException(err);
      setError('Something went wrong. Please try again.');
      setLoading(false);
    }
  }

  const stepsActionHandler = async (params) => {
    try {
      setLoading(true);
      if (selectedBiller) {
        switch (currentStep) {
          case 1: {
            if (selectedBiller.code === BPICC_CODE) {
              // console.log('step1 params', params);
              if ('creditAccount' in params) setCreditAccount(params.creditAccount);
              if ('mode' in params) setCreditPaymentMode(params.mode);
              setLoading(false);
              setCurrentStep(currentStep + 1);
              setShowCcStep1Modal(false);
              setShowCcStep2Modal(true);
            } else {
              if ('referenceNumber' in params) setReferenceNumber(params.referenceNumber);
              await createEnrolment(selectedBiller.id, params.referenceNumber);
            }
            break;
          }
          case 2: {
            if (selectedBiller.code === BPICC_CODE) {
              // console.log('step2 params', params);
              if ('account' in params) setAccount(params.account);
              setLoading(false);
              setCurrentStep(currentStep + 1);
              setShowCcStep2Modal(false);
              setShowCcStep3Modal(true);
            } else {
              if ('account' in params) setAccount(params.account);
              setLoading(false);
              setCurrentStep(currentStep + 1);
              setShowStep2Modal(false);
              setShowStep3Modal(true);
            }
            break;
          }
          case 3: {
            //console.log(`sending pin to ${mobileNumber}`)
            if (selectedBiller.code === BPICC_CODE) {
              await createBpiCcCharge();
            } else {
              await createCharge();
            }
            break;
          }
          case 4: {
            if (selectedBiller.code === BPICC_CODE) {
              if ('pin' in params) setOtp(params.pin);
              await verifyBpiCcPin(params.pin);
            } else {
              if ('pin' in params) setOtp(params.pin);
              await verifyPin(params.pin);
            }
            break;
          }
          case 5: {
            if (selectedBiller.code === BPICC_CODE) {
              setShowCcStep5Modal(false);
            } else {
              setShowStep5Modal(false);
            }
            reset();
            break;
          }
        }
      }
    } catch (e) {
      Sentry.captureException(e);
    }
  }
  
  return (
    <div className="antialiased min-h-screen">
      <Head>
        <title>BPI eADA</title>
        <link rel="icon" href="/favicon.png" />
        <link rel="stylesheet" href="https://use.typekit.net/hxw2jwi.css" />
        <link rel="stylesheet" href="/css/custom.css" />
      </Head>

      <div className="relative bg-transparent overflow-hidden flex flex-col">
        <div className="block absolute inset-y-0 h-128 w-full bg-center bg-cover" style={{ backgroundImage: "url('/img/hero_home.jpg')" }}>
          <div className="relative h-full w-full">
            {/* <svg className="absolute right-full transform translate-y-1/4 translate-x-1/4 lg:translate-x-1/2" width="404" height="784" fill="none" viewBox="0 0 404 784">
              <defs>
                <pattern id="svg-pattern-squares-1" x="0" y="0" width="20" height="20" patternUnits="userSpaceOnUse">
                  <rect x="0" y="0" width="4" height="4" className="text-gray-200" fill="currentColor" />
                </pattern>
              </defs>
              <rect width="404" height="784" fill="url(#svg-pattern-squares-1)" />
            </svg>
            <svg className="absolute left-full transform -translate-y-3/4 -translate-x-1/4 md:-translate-y-1/2 lg:-translate-x-1/2" width="404" height="784" fill="none" viewBox="0 0 404 784">
              <defs>
                <pattern id="svg-pattern-squares-2" x="0" y="0" width="20" height="20" patternUnits="userSpaceOnUse">
                  <rect x="0" y="0" width="4" height="4" className="text-gray-200" fill="currentColor" />
                </pattern>
              </defs>
              <rect width="404" height="784" fill="url(#svg-pattern-squares-2)" />
            </svg> */}
          </div>
        </div>

        <div className="relative py-4">
          <div className="max-w-screen-xl mx-auto px-4 sm:px-6">
            <nav className="relative flex items-center justify-between sm:h-14 md:justify-center">
              <div className="flex items-center flex-1 md:absolute md:inset-y-0 md:left-0">
                <div className="flex items-center justify-between w-full md:w-auto">
                  <Link href="/">
                    <img className="h-10 w-auto sm:h-14 cursor-pointer" src="/img/BPI-logo-2022.png" alt="BPI" />
                  </Link>
                  {/* <div className="-mr-2 flex items-center md:hidden">
                    <button onClick={() => setIsOpen(!isOpen)} type="button" className="inline-flex items-center justify-center p-2 rounded-md text-gray-400 hover:text-gray-500 hover:bg-gray-100 focus:outline-none focus:bg-gray-100 focus:text-gray-500 transition duration-150 ease-in-out">
                      <svg className="h-6 w-6" stroke="currentColor" fill="none" viewBox="0 0 24 24">
                        <path strokeLinecap="round" strokeLinejoin="round" strokeWidth="2" d="M4 6h16M4 12h16M4 18h16" />
                      </svg>
                    </button>
                  </div> */}
                </div>
              </div>
              {/* <div className="hidden md:block">
                <a href="#" className="font-medium text-gray-500 hover:text-gray-900 focus:outline-none focus:text-gray-900 transition duration-150 ease-in-out">Product</a>
                <a href="#" className="ml-10 font-medium text-gray-500 hover:text-gray-900 focus:outline-none focus:text-gray-900 transition duration-150 ease-in-out">Features</a>
                <a href="#" className="ml-10 font-medium text-gray-500 hover:text-gray-900 focus:outline-none focus:text-gray-900 transition duration-150 ease-in-out">Marketplace</a>
                <a href="#" className="ml-10 font-medium text-gray-500 hover:text-gray-900 focus:outline-none focus:text-gray-900 transition duration-150 ease-in-out">Company</a>
              </div> */}
              <div className="absolute flex items-center justify-end inset-y-0 right-0">
                <span className="inline-flex rounded-md shadow-none mr-2">
                  <Link href="/faq">
                    <a className="inline-flex items-center px-2 py-1.5 border border-transparent text-sm sm:text-base leading-6 font-semibold rounded-md bg-white text-gray-900 md:text-white md:bg-transparent transition duration-150 ease-in-out">
                      FAQs
                    </a>
                  </Link>
                </span>
                <span className="inline-flex rounded-md shadow-none">
                  <Link href="/terms-and-conditions">
                    <a className="inline-flex items-center px-2 py-1.5 border border-transparent text-sm sm:text-base leading-6 font-semibold rounded-md bg-white text-gray-900 md:text-white md:bg-transparent transition duration-150 ease-in-out">
                      Terms and Conditions
                    </a>
                  </Link>
                </span>
              </div>
            </nav>
          </div>

          {/* Mobile Nav */}
          {/* <div className="absolute top-0 inset-x-0 p-2 md:hidden">
            <Transition
              show={isOpen}
              enter="duration-150 ease-out"
              enterFrom="opacity-0 scale-95"
              enterTo="opacity-100 scale-100"
              leave="duration-100 ease-in"
              leaveFrom="opacity-100 scale-100"
              leaveTo="opacity-0 scale-95">
              <div className="rounded-lg shadow-md transition transform origin-top-right">
                <div className="rounded-lg bg-white shadow-xs overflow-hidden">
                  <div className="px-5 pt-4 flex items-center justify-between">
                    <div>
                      <img className="h-8 w-auto" src="/img/BPI-logo-2020.svg" alt="BPI" />
                    </div>
                    <div className="-mr-2">
                      <button onClick={() => setIsOpen(!isOpen)} type="button" className="inline-flex items-center justify-center p-2 rounded-md text-gray-400 hover:text-gray-500 hover:bg-gray-100 focus:outline-none focus:bg-gray-100 focus:text-gray-500 transition duration-150 ease-in-out">
                        <svg className="h-6 w-6" stroke="currentColor" fill="none" viewBox="0 0 24 24">
                          <path strokeLinecap="round" strokeLinejoin="round" strokeWidth="2" d="M6 18L18 6M6 6l12 12" />
                        </svg>
                      </button>
                    </div>
                  </div>
                  <div className="px-2 pt-2 pb-3">
                    <a href="#" className="block px-3 py-2 rounded-md text-base font-medium text-gray-700 hover:text-gray-900 hover:bg-gray-50 focus:outline-none focus:text-gray-900 focus:bg-gray-50 transition duration-150 ease-in-out">Product</a>
                    <a href="#" className="mt-1 block px-3 py-2 rounded-md text-base font-medium text-gray-700 hover:text-gray-900 hover:bg-gray-50 focus:outline-none focus:text-gray-900 focus:bg-gray-50 transition duration-150 ease-in-out">Features</a>
                    <a href="#" className="mt-1 block px-3 py-2 rounded-md text-base font-medium text-gray-700 hover:text-gray-900 hover:bg-gray-50 focus:outline-none focus:text-gray-900 focus:bg-gray-50 transition duration-150 ease-in-out">Marketplace</a>
                    <a href="#" className="mt-1 block px-3 py-2 rounded-md text-base font-medium text-gray-700 hover:text-gray-900 hover:bg-gray-50 focus:outline-none focus:text-gray-900 focus:bg-gray-50 transition duration-150 ease-in-out">Company</a>
                  </div>
                  <div>
                    <a href="#" className="block w-full px-5 py-3 text-center font-medium text-indigo-600 bg-gray-50 hover:bg-gray-100 hover:text-indigo-700 focus:outline-none focus:bg-gray-100 focus:text-indigo-700 transition duration-150 ease-in-out">
                      Log in
                    </a>
                  </div>
                </div>
              </div>
            </Transition>
          </div> */}

          {/** Card  **/}
          <div className="mt-12 max-w-screen-xl mx-auto px-4 sm:px-6 sm:mt-14 md:mt-14 lg:mt-16 xl:mt-20">
            <div className="bg-white bg-opacity-75 rounded-lg shadow-lg p-8 pb-12 max-w-full md:max-w-sm lg:max-w-md xl:ml-20">
              <div className="text-center">
                <h2 className="text-xl tracking-tight leading-relaxed font-extrabold text-gray-900 sm:text-2xl sm:leading-none md:text-3xl">
                  BPI Auto Debit Arrangement (ADA)
                </h2>
                {/* <p className="mt-2 text-gray-600 font-medium text-md mx-16 md:mx-2">
                  The quickest and easiest auto-debit arrangement enrollment there is.
                </p> */}
                <p className="mt-6 text-gray-600 text-left text-base sm:text-base md:text-sm lg:text-base mx-2">
                  Set-and-forget recurring payments from your BPI Online account. Enroll now in Auto Debit Arrangement to ensure worry-free payments.
                </p>
                {/* <p className="mt-5 text-gray-500 font-medium text-sm mx-4">
                  Stop stressing out tracking your bills every month or quarter. Conveniently enroll your  BPI Online account to your billers and say goodbye to missed payments, penalties, or potential bad credit ratings.
                </p> */}
                <p className="mt-4 text-gray-600 text-left text-base sm:text-base md:text-sm lg:text-base mx-2">
                  Before enrolling, please check with your biller if your account is eligible.
                </p>
              </div>
            </div>
          </div>

          
            

          <div className="bg-gray-100 text-center text-xl lg:text-2xl font-semibold p-5 mt-24">
          {selectedCategory ? "":"Categories"}
          </div>

          {/** Content  **/}
          <div className="max-w-screen-xl lg:px-10 mt-10 sm:mt-12 md:mt-10 lg:mt-10 mx-auto pb-6">

            <div className="px-4 py-4 sm:px-4 md:px-4 transition-all duration-500 ease-in-out">
              <div className="mt-1 max-w-7xl mx-auto relative">
                <div className="absolute inset-y-0 left-0 pl-3 flex items-center pointer-events-none">
                  <svg className="search h-5 w-5 text-gray-400" viewBox="0 0 20 20" fill="currentColor"><path fillRule="evenodd" d="M8 4a4 4 0 100 8 4 4 0 000-8zM2 8a6 6 0 1110.89 3.476l4.817 4.817a1 1 0 01-1.414 1.414l-4.816-4.816A6 6 0 012 8z" clipRule="evenodd"></path></svg>
                </div>
                <input id="search" className="transition-colors duration-100 ease-in-out border border-transparent py-2.5 pr-4 pl-10 text-gray-600 select-text appearance-none leading-normal rounded-lg block w-full placeholder-gray-400 focus:outline-none text-left select-none truncate focus:bg-white focus:border-gray-300 focus:shadow-lg bg-gray-200" placeholder="Search billers" onChange={(e) => onSearch(e.target.value)} />
              </div>
              { showCategory ?
                <div id="categoryTab" className="mx-auto max-w-7xl w-full text-center lg:text-left">
                  
              

                    <div className="px-8 lg:px-10 md:px-10 grid grid-flow-row-dense place-content-evenly grid-cols-3 md:grid-cols-6 lg:grid-cols-6 xl:grid-cols-6 gap-6 py-8">
            
                {categories.map((categories, idx) => (
                  <div className="relative">
                    <button
                      key={idx}
                      type="button"
                      onClick={() => handleSelectCategory(categories.category)}
                      className={cx(
                        "bg-transparent p-0 border-0 rounded-lg outline-none cursor-pointer focus:outline-none active:shadow-outline-red transition-all duration-200 ease-in-out hover:shadow-xl"
                      )}
                    >
                      <span className="col-span-1 flex align-top flex-col bg-white rounded-lg sm:shadow overflow-hidden min-h-full h-50 w-full">
                        <div
                          className={cx("flex-1 flex flex-col p-3")}
                        >
                          <img className="w-full h-auto" src={categories.logo} />
                        </div>
                        <div className=" sm:border-t sm:border-gray-200 h-10">
                          <p className="max-h-5 text-sm w-full sm:py-2 sm:text-sm md:text-sm lg:text-sm text-gray-800 leading-5">
                            {categories.category}
                          </p>
                        </div>
                      </span>
                    </button>
                    {/* <div className="absolute inset-0 bg-white bg-opacity-70 cursor-default pointer-events-none"></div>
                    <div className="absolute inset-0 flex items-center justify-center">
                      <div className="px-3 py-2 bg-black rounded bg-opacity-80 leading-4">
                        <span className="inline text-xs font-semibold text-white uppercase proportional-nums whitespace-nowrap select-none">Not available</span>
                      </div>
                    </div> */}
                  </div>
              ))}
                  </div>
                </div>
              :
              <div>
              {isMobile ?
                  <div className="pt-1 w-full text-gray-900 border border-gray-200 dark:bg-gray-700 dark:border-gray-600 dark:text-white">
                  <div className="bg-gray-100 text-sm lg:text-2xl font-semibold grid grid-cols-3 justify-items-center text-center">
                    <div>
                    { selectedCategory == ayalaLandProperties ?
                    <button
                    type="button"
                    onClick={() => handleSelectCategory("back.real.estate")}
                    className="inline-flex relative items-center py-2 px-4 w-full text-xs text-gray-600 rounded-t-lg border-b border-gray-200 hover:bg-gray-100 hover:text-blue-700 focus:z-10 focus:ring-2 focus:ring-blue-700 focus:text-blue-700 dark:border-gray-600 dark:hover:bg-gray-600 dark:hover:text-white dark:focus:ring-gray-500 dark:focus:text-white">
                    <svg xmlns="http://www.w3.org/2000/svg" className="h-10 w-10 object-scale-down" fill="none" viewBox="0 0 24 24" stroke="#b50808" strokeWidth={3}>
                      <path strokeLinecap="round" strokeLinejoin="round" d="M15 19l-7-7 7-7" />
                    </svg>
                    </button>
                    :
                    <button
                      type="button"
                      onClick={() => handleSelectCategory("back")}
                      className="inline-flex relative items-center py-2 px-4 w-full text-xs text-gray-600 rounded-t-lg border-b border-gray-200 hover:bg-gray-100 hover:text-blue-700 focus:z-10 focus:ring-2 focus:ring-blue-700 focus:text-blue-700 dark:border-gray-600 dark:hover:bg-gray-600 dark:hover:text-white dark:focus:ring-gray-500 dark:focus:text-white">
                      <svg xmlns="http://www.w3.org/2000/svg" className="h-10 w-10 object-scale-down" fill="none" viewBox="0 0 24 24" stroke="#b50808" strokeWidth={3}>
                        <path strokeLinecap="round" strokeLinejoin="round" d="M15 19l-7-7 7-7" />
                      </svg>
                      </button>
                      }
                    </div>
                    <div className="h-10 py-5">
                      {selectedCategory}
                    </div>
                    <div>
                    </div>
                  </div>
                  { selectedCategory == "Real Estate" ? (
                    <button type="button"
                      id={billers.id}
                      key={billers.id}
                      onClick={() => handleSelectCategory(ayalaCategory.category)}
                      className="inline-flex uppercase relative items-center py-2 px-4 w-full text-left text-xs text-gray-600 rounded-t-lg border-b border-gray-200 hover:bg-gray-100 hover:text-blue-700 focus:z-10 focus:ring-2 focus:ring-blue-700 focus:text-blue-700 dark:border-gray-600 dark:hover:bg-gray-600 dark:hover:text-white dark:focus:ring-gray-500 dark:focus:text-white">
                      <img className="object-scale-down h-10 w-15 pr-5" src={ayalaCategory.logo} />
                      {ayalaCategory.category}
                    </button>
                  ) : null }

                  {billers.map((billers) => (
                    <button type="button"
                      id={billers.id}
                      key={billers.id}
                      onClick={() => onSelectBillerHandler(billers.id)}
                      className="inline-flex uppercase relative items-center py-2 px-4 w-full text-left text-xs text-gray-600 rounded-t-lg border-b border-gray-200 hover:bg-gray-100 hover:text-blue-700 focus:z-10 focus:ring-2 focus:ring-blue-700 focus:text-blue-700 dark:border-gray-600 dark:hover:bg-gray-600 dark:hover:text-white dark:focus:ring-gray-500 dark:focus:text-white">
                      <img className="object-scale-down h-10 w-15 pr-5" src={billers.image} />
                      {billers.name}
                    </button>
                  ))}
                </div>
              :
              <div>
                <div className="bg-gray-100 text-center text-xl lg:text-2xl font-semibold p-5 mt-1">
                    {selectedCategory}
                </div>
                  <ul className="mt-6 mx-auto grid grid-cols-2 gap-6 sm:grid-cols-3 md:grid-cols-6 transition-all duration-500 ease-in-out">
                    { selectedCategory == ayalaLandProperties ? (
                      <button key={123} type="button" onClick={() => handleSelectCategory("back.real.estate")} className="bg-transparent p-0 rounded-lg outline-none focus:outline-none active:shadow-outline-red transition-all duration-200 ease-in-out">
                        <li className="col-span-1 flex flex-col text-center bg-white rounded-lg overflow-hidden">
                          <div className="flex-1 flex flex-col">
                            <svg xmlns="http://www.w3.org/2000/svg" className="h-50 w-50" fill="none" viewBox="0 0 24 24" stroke="#b50808" strokeWidth={2}>
                              <path strokeLinecap="round" strokeLinejoin="round" d="M15 19l-7-7 7-7" />
                            </svg>
                          </div>
                        </li>
                      </button>
                    ) : (
                      <button key={123} type="button" onClick={() => handleSelectCategory("back")} className="bg-transparent p-0 rounded-lg outline-none focus:outline-none active:shadow-outline-red transition-all duration-200 ease-in-out">
                        <li className="col-span-1 flex flex-col text-center bg-white rounded-lg overflow-hidden">
                          <div className="flex-1 flex flex-col">
                            <svg xmlns="http://www.w3.org/2000/svg" className="h-50 w-50" fill="none" viewBox="0 0 24 24" stroke="#b50808" strokeWidth={2}>
                              <path strokeLinecap="round" strokeLinejoin="round" d="M15 19l-7-7 7-7" />
                            </svg>
                          </div>
                        </li>
                      </button>
                      )}
                                
                    { selectedCategory == "Real Estate" ? (
                      <div>
                        <button
                          type="button"
                          onClick={() => handleSelectCategory(ayalaCategory.category)}
                          className={cx(
                            "bg-transparent p-0 border-0 rounded-lg outline-none focus:outline-none active:shadow-outline-red transition-all duration-200 ease-in-out hover:shadow-xl"
                          )}
                        >
                          <span className="col-span-1 flex  align-top flex-col bg-white rounded-lg sm:shadow overflow-hidden h-50 w-full">
                            <div
                              className={cx("flex-1 flex flex-col p-3")}
                            >
                              <img className="w-full h-auto" src={ayalaCategory.logo} />
                            </div>
                            <div className=" sm:border-t sm:border-gray-200 h-10">
                              <p className="max-h-5 text-sm w-full sm:py-2 sm:text-sm md:text-sm lg:text-sm text-gray-800 leading-5">
                                {ayalaCategory.category}
                              </p>
                            </div>
                          </span>
                        </button>
                    </div>
                    ) : null}

                    {billers.map((biller) => 
                        <button key={biller.id} type="button" onClick={() => onSelectBillerHandler(biller.id)} className="bg-transparent p-0 border border-gray-100 rounded-lg outline-none focus:outline-none hover:shadow-xl active:shadow-outline-red transition-all duration-200 ease-in-out">
                          <li className="col-span-1 flex flex-col text-center bg-white rounded-lg shadow overflow-hidden">
                            <div className="flex-1 flex flex-col">
                              <img className="w-full h-auto" src={biller.image} />
                            </div>
                            <div className="relative border-t border-gray-200 py-3">
                              <p className="min-h-10 h-10 max-h-10 px-3 text-sm text-gray-800 leading-5 line-clamp-2">{biller.name}</p>
                            </div>
                          </li>
                        </button>
                      )
                    }
                  </ul>
                </div>
              }
              </div>
              
            }

              {/* <CategoryTabs
                categories={categories}
                selected={selectedTab}
                onSelectCategory={onSelectCategoryHandler}
              />

              {
                billers.length > 0
                ? <BillerList
                  billers={billers}
                  onSelectBiller={onSelectBillerHandler}
                />
                : <div className="flex items-center justify-center bg-transparent rounded-lg border-4 border-gray-200 border-dashed mt-6 max-w-screen-md mx-auto p-12">
                    <p className="text-lg font-medium text-gray-400">No billers found</p>
                  </div>
              } */}
              
            </div>
          </div>


        </div>
       <Footer />
      </div>

      <TermsModal
        visible={showTermsModal}
        biller={selectedBiller}
        loading={loading}
        onClose={() => {
          setShowTermsModal(false);
          reset();
        }}
        onActionClick={termsActionHandler}
      />
      <Step1Modal
        visible={showStep1Modal}
        biller={selectedBiller}
        referenceNumber={referenceNumber}
        loading={loading}
        error={error}
        onClose={() => {
          setLoading(false);
          setShowStep1Modal(false);
          reset();
        }}
        onActionClick={stepsActionHandler}
      />
      <Step2Modal
        visible={showStep2Modal}
        biller={selectedBiller}
        referenceNumber={referenceNumber}
        accounts={accounts}
        account={account}
        loading={loading}
        error={error}
        onClose={() => {
          setLoading(false);
          setShowStep2Modal(false);
          reset();
        }}
        onActionClick={stepsActionHandler}
      />
      <Step3Modal
        visible={showStep3Modal}
        biller={selectedBiller}
        referenceNumber={referenceNumber}
        mobileNumber={mobileNumber}
        loading={loading}
        error={error}
        onClose={() => {
          setLoading(false);
          setShowStep3Modal(false);
          reset();
        }}
        onActionClick={stepsActionHandler}
      />
      <Step4Modal
        visible={showStep4Modal}
        biller={selectedBiller}
        referenceNumber={referenceNumber}
        mobileNumber={mobileNumber}
        otp={otp}
        loading={loading}
        error={error}
        onClose={() => {
          setShowStep4Modal(false);
          reset();
        }}
        onActionClick={stepsActionHandler}
      />
      <Step5Modal
        visible={showStep5Modal}
        biller={selectedBiller}
        referenceNumber={referenceNumber}
        onClose={() => {
          setShowStep5Modal(false);
          reset();
        }}
        onActionClick={stepsActionHandler}
      />
      <LoadingModal visible={showLoadingModal} />
      <ErrorModal
        visible={showErrorModal}
        message={error}
        onClose={() => {
          setShowErrorModal(false);
          reset();
        }}
        onCloseClick={() => {
          setShowErrorModal(false);
          reset();
        }}
      />
      <BpiCcStep1Modal
        visible={showCcStep1Modal}
        biller={selectedBiller}
        accounts={creditAccounts}
        account={creditAccount}
        loading={loading}
        error={error}
        onClose={() => {
          setLoading(false);
          setShowCcStep1Modal(false);
          reset();
        }}
        onActionClick={stepsActionHandler}
      />
      <BpiCcStep2Modal
        visible={showCcStep2Modal}
        biller={selectedBiller}
        creditAccount={creditAccount}
        accounts={accounts}
        account={account}
        loading={loading}
        error={error}
        onClose={() => {
          setLoading(false);
          setShowCcStep2Modal(false);
          reset();
        }}
        onActionClick={stepsActionHandler}
      />
      <BpiCcStep3Modal
        visible={showCcStep3Modal}
        biller={selectedBiller}
        creditAccount={creditAccount}
        mobileNumber={mobileNumber}
        loading={loading}
        error={error}
        onClose={() => {
          setLoading(false);
          setShowCcStep3Modal(false);
          reset();
        }}
        onActionClick={stepsActionHandler}
      />
      <BpiCcStep4Modal
        visible={showCcStep4Modal}
        biller={selectedBiller}
        creditAccount={creditAccount}
        mobileNumber={mobileNumber}
        otp={otp}
        loading={loading}
        error={error}
        onClose={() => {
          setShowCcStep4Modal(false);
          reset();
        }}
        onActionClick={stepsActionHandler}
      />
      <BpiCcStep5Modal
        visible={showCcStep5Modal}
        biller={selectedBiller}
        creditAccount={creditAccount}
        mode={creditPaymentMode}
        onClose={() => {
          setShowCcStep5Modal(false);
          reset();
        }}
        onActionClick={stepsActionHandler}
      />
    </div>
  )
}

export default Home