import React, { useState, useEffect } from 'react'
import Modal from 'react-modal'
import cn from 'classnames'

import TermsContent from './termsContent';
import BpiCcTermsContent from './bpiccTermsContent';
import { BPICC_CODE } from '../pages';

const TermsModal = ({ visible, biller, loading, onClose, onActionClick }) => {
  Modal.setAppElement('#__next');
  const [agree, setAgree] = useState(false);
  const [disabled, setDisabled] = useState(true);

  useEffect(() => {
    if (visible) {
      setAgree(false);
    }
  }, [visible]);

  const onScroll = (e) => {
    const height = e.target.clientHeight;
    const scroll = e.target.scrollHeight - e.target.scrollTop;
    if ((scroll - height) < 50) {
      setDisabled(false);
    } else {
      setDisabled(true);
    }
  }

  const onCloseModal = () => {
    setAgree(false);
    setDisabled(true);
    onClose();
  }

  const onContinue = () => {
    onActionClick({ agree: true });
    setAgree(false);
    setDisabled(true);
  }
 
  return (
    <Modal
      isOpen={visible}
      className="centered relative w-auto items-center sm:max-w-screen-md sm:mx-auto m-2 my-8 outline-none"
      overlayClassName="fixed inset-0 bg-cool-gray-500 bg-opacity-75 overflow-x-hidden overflow-y-auto"
      onRequestClose={onCloseModal}
      shouldCloseOnOverlayClick={false}
      shouldCloseOnEsc={false}
      closeTimeoutMS={500}
    >
      <div className="relative">
        <button className="h-auto w-auto p-2 bg-transparent outline-none focus:outline-none absolute top-1 right-1 text-gray-400 hover:text-gray-600 transition-opacity duration-300 ease-in" onClick={onCloseModal}>
          <svg className="w-6 h-6" fill="currentColor" viewBox="0 0 20 20" xmlns="http://www.w3.org/2000/svg"><path fillRule="evenodd" d="M4.293 4.293a1 1 0 011.414 0L10 8.586l4.293-4.293a1 1 0 111.414 1.414L11.414 10l4.293 4.293a1 1 0 01-1.414 1.414L10 11.414l-4.293 4.293a1 1 0 01-1.414-1.414L8.586 10 4.293 5.707a1 1 0 010-1.414z" clipRule="evenodd"></path></svg>
        </button>
        <div className="w-full bg-white bg-clip-padding rounded-lg shadow-xl p-6 sm:p-10 outline-none">
          <div className="border border-cool-gray-200 rounded-md">
            {biller ? biller.code === BPICC_CODE ?
              <BpiCcTermsContent onScrollHandler={(e) => onScroll(e)} /> : 
              <TermsContent onScrollHandler={(e) => onScroll(e)} /> :
              <TermsContent onScrollHandler={(e) => onScroll(e)} />
            }
          </div>
          
          <div className="mt-6 sm:mt-6">
            <div className="relative flex items-center mb-4">
              <div className="flex items-center h-5">
                <input disabled={disabled} id="agree" checked={agree} onChange={() => setAgree(!agree)} type="checkbox" className={cn("form-checkbox h-4 w-4 text-brand-500 rounded focus:ring-red-200 focus:ring transition duration-150 ease-in-out", { "bg-gray-100": disabled, "border-gray-200": disabled })} />
              </div>
              <div className="pl-4 text-sm leading-5">
                <label htmlFor="agree" className={cn("font-medium text-gray-700", { "opacity-50 cursor-default": disabled, "cursor-pointer": !disabled })}>I agree to Terms and Conditions of this service.</label>
              </div>
            </div>
            <span className="flex w-full rounded-md shadow-sm">
              <button disabled={!agree || loading} onClick={onContinue} type="button" className={cn("inline-flex items-center justify-center w-full rounded-md border border-transparent px-6 py-3 bg-brand-500 text-base leading-6 font-medium text-white shadow-sm focus:outline-none focus:border-brand-700 focus:shadow-outline-red transition ease-in-out duration-150", {
                'opacity-50 cursor-default': !agree || loading,
                'opacity-100 cursor-pointer hover:bg-brand-700': agree && !loading
              })}>
                {loading && <svg className="animate-spin -ml-1 mr-3 h-4 w-4 text-white" xmlns="http://www.w3.org/2000/svg" fill="none" viewBox="0 0 24 24">
                  <circle className="opacity-25" cx="12" cy="12" r="10" stroke="currentColor" strokeWidth="4"></circle>
                  <path className="opacity-75" fill="currentColor" d="M4 12a8 8 0 018-8V0C5.373 0 0 5.373 0 12h4zm2 5.291A7.962 7.962 0 014 12H0c0 3.042 1.135 5.824 3 7.938l3-2.647z"></path>
                </svg>
                }
                  {loading ? 'Please wait...' : 'Continue' }
              </button>
            </span>
          </div>
        </div>
      </div>
    </Modal>
  );
}

export default TermsModal;